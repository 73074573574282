import { Fragment, useEffect, useState } from "react"
import APIRequest from "../../../../helpers/CreateRequest"
import { Currency } from "../../../../helpers/InputValueConverter"
import MultiSelectField from "../../../../components/atoms/forms/SimpleFields/MultiSelectField"
import { option } from "../HouseHistoryReport/HouseHistory"
import PieChart from "./Piechart"

type EventStatus = "Actual" | "Estimated" | "Scheduled"

type event = {
    CommunityNameCode: string
    EventStatus: EventStatus
    EventType: "Sale" | "Start" | "Settlement"
    EventYear: string
    GrossProfitAmount: number,
    TotalSalesPriceAmount: number,
    HouseCode: string
}

export type multiEventTypeHouse = {
    CommunityNameCode: string
    EventStatus: EventStatus
    SaleEventYear?: string
    StartEventYear?: string
    SettlementEventYear?: string
    GrossProfitAmount: number
    TotalSalesPriceAmount: number,
    HouseCode: string
}

type EventMap = Map<EventStatus, { CommunityNameCode: string, houses: multiEventTypeHouse[] }[]>

type eventMapArray = [EventStatus, {
    CommunityNameCode: string;
    houses: multiEventTypeHouse[];
}[]][]

type filters = {
    Community: string[],
    Year: string[],
}

type options = {
    Community: option[],
    Year: option[],
}



export default function ProjectedPerformanceReport({ ScenarioID, triggerNewData }: { ScenarioID: string, triggerNewData: boolean }) {
    const [eventsBackup, setEventsBackup] = useState<event[]>()
    const [eventMap, setEventMap] = useState<eventMapArray>()
    const [houseList, setHouseList] = useState<multiEventTypeHouse[]>([])
    const [openEventStatus, setOpenEventStatus] = useState<string[]>([])
    const [openCommunities, setOpenCommunities] = useState<string[]>([])



    const [filters, setFilters] = useState<filters>({
        Community: [],
        Year: [],
    })

    const [options, setOptions] = useState<options>({
        Community: [],
        Year: [],
    })

    const getData = async () => {
        try {
            const result = await new APIRequest('/scenariosSalesForecastEvents/projectedPerformance', 'POST', null, { scenarioID: ScenarioID }).GenerateRequest()
            if (result.status === 200) {
                const body = await result.json()
                if (body.status === 200) {
                    setEventsBackup(body.recordset)
                } else throw body.message
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleData = (eventList: event[]) => {
        const multiEventTypeHouses = convertEventsToMultiEventTypeHouses(eventList)
        getFilterOptions(multiEventTypeHouses)
        const houses: multiEventTypeHouse[] = filterData(multiEventTypeHouses)

        setHouseList(houses)

        // Divide data into sections by event status
        const eventMapTemp: EventMap = new Map().set("Actual", []).set("Estimated", []).set("Scheduled", [])
        for (let i = 0; i < houses.length; i++) {
            const house = houses[i]
            const communities = eventMapTemp.get(house.EventStatus)
            if (communities) {
                let community = communities.find(community => community.CommunityNameCode === house.CommunityNameCode)
                if (community) community.houses.push(house)
                else communities.push({ CommunityNameCode: house.CommunityNameCode, houses: [house] })
            } else {
                eventMapTemp.set(house.EventStatus, [{ CommunityNameCode: house.CommunityNameCode, houses: [house] }])
            }
        }


        // Order EventMap communities by community name and houses by house code
        const eventMapOrdered: EventMap = new Map()
        eventMapTemp.forEach((communities, eventStatus) => {
            const communitiesOrdered = communities.sort((a, b) => a.CommunityNameCode.localeCompare(b.CommunityNameCode))
            communitiesOrdered.forEach(community => {
                community.houses.sort((a, b) => a.HouseCode.localeCompare(b.HouseCode))
            })
            eventMapOrdered.set(eventStatus, communitiesOrdered)
        })

        // convert Event map to nested array to be rendered just like the map is nested 
        const eventMapArray = Array.from(eventMapOrdered)

        setEventMap(eventMapArray)



    }

    const filterData = (housesList: multiEventTypeHouse[]) => {
        let filteredHouses: multiEventTypeHouse[] = housesList

        // filter by community and name 
        filteredHouses = filteredHouses.filter(house => {
            if (filters.Community.length > 0 && !filters.Community.includes(house.CommunityNameCode)) return false
            if (filters.Year.length > 0 && !(filters.Year.includes(house.SaleEventYear ?? '') || filters.Year.includes(house.StartEventYear ?? '') || filters.Year.includes(house.SettlementEventYear ?? ''))) return false

            return true
        })

        return filteredHouses
    }

    const convertEventsToMultiEventTypeHouses = (housesList: event[]) => {
        let filteredHouses: multiEventTypeHouse[] = []
        
        // combine any houses with the same event status, community, and house code into a multiEventTypeHouse object
        housesList.forEach(house => {
            let newHouse: multiEventTypeHouse = house
            let found = false
            filteredHouses.forEach(h => {
                if (h.CommunityNameCode === house.CommunityNameCode && h.HouseCode === house.HouseCode && h.EventStatus === house.EventStatus) {
                    found = true
                    if (house.EventType === "Sale") {
                        h.SaleEventYear = house.EventYear
                    } else if (house.EventType === "Start") {
                        h.StartEventYear = house.EventYear
                    } else if (house.EventType === "Settlement") {
                        h.SettlementEventYear = house.EventYear
                    } else{
                        console.error("Invalid event type")
                    }
                }
            })
            if (!found) {
                if (house.EventType === "Sale") {
                    newHouse.SaleEventYear = house.EventYear
                } else if (house.EventType === "Start") {
                    newHouse.StartEventYear = house.EventYear
                } else if (house.EventType === "Settlement") {
                    newHouse.SettlementEventYear = house.EventYear
                } else{
                    console.error("Invalid event type")
                }
                filteredHouses.push(newHouse)
            }
        })

        return filteredHouses
    }

    const getFilterOptions = (houses: multiEventTypeHouse[]) => {
        // get the unique values for each filter
        const tempOptions: options = {
            Community: [],
            Year: [],

        }

        houses.forEach(house => {
            if (!tempOptions.Community.some(option => option.value === house.CommunityNameCode)) {
                tempOptions.Community.push({ value: house.CommunityNameCode, name: String(house.CommunityNameCode) });
            }
            if (house.SaleEventYear && !tempOptions.Year.some(option => option.value === house.SaleEventYear)) {
                tempOptions.Year.push({ value: house.SaleEventYear, name: String(house.SaleEventYear) });
            }
            if (house.StartEventYear && !tempOptions.Year.some(option => option.value === house.StartEventYear)) {
                tempOptions.Year.push({ value: house.StartEventYear, name: String(house.StartEventYear) });
            }
            if (house.SettlementEventYear && !tempOptions.Year.some(option => option.value === house.SettlementEventYear)) {
                tempOptions.Year.push({ value: house.SettlementEventYear, name: String(house.SettlementEventYear) });
            }
        });

        // order the options
        tempOptions.Community.sort((a, b) => a.name.localeCompare(b.name))
        tempOptions.Year.sort((a, b) => a.name.localeCompare(b.name))

        setOptions(tempOptions)
    }

    const handleClickedObject = (type: 'eventStatus' | 'community', name: string) => {
        if (type === 'eventStatus') {
            if (openEventStatus.includes(name)) {
                setOpenEventStatus(openEventStatus.filter(event => event !== name))
            } else {
                setOpenEventStatus([...openEventStatus, name])
            }
        } else if (type === 'community') {
            if (openCommunities.includes(name)) {
                setOpenCommunities(openCommunities.filter(community => community !== name))
            } else {
                setOpenCommunities([...openCommunities, name])
            }
        }
    }

    const calculateTotals = (houses: multiEventTypeHouse[]) => {
        const totals = {
            Sale: {
                Units: 0,
                TotalSales: 0,
                Gross: 0,
                Avg: 0
            },
            Start: {
                Units: 0,
                TotalSales: 0,
                Gross: 0,
                Avg: 0
            },
            Settlement: {
                Units: 0,
                TotalSales: 0,
                Gross: 0,
                Avg: 0
            }
        }
        for (let i = 0; i < houses.length; i++) {

            const house = houses[i]
            if (house.SaleEventYear && (filters.Year.length === 0 || filters.Year.includes(house.SaleEventYear))) {
                totals.Sale.Units++
                totals.Sale.TotalSales += house.TotalSalesPriceAmount
                totals.Sale.Gross += house.GrossProfitAmount
                totals.Sale.Avg = (((totals.Sale.Gross / totals.Sale.TotalSales) || 0) * 100)
            }
            if (house.StartEventYear && (filters.Year.length === 0 || filters.Year.includes(house.StartEventYear))) {
                totals.Start.Units++
                totals.Start.TotalSales += house.TotalSalesPriceAmount
                totals.Start.Gross += house.GrossProfitAmount
                totals.Start.Avg = (((totals.Start.Gross / totals.Start.TotalSales) || 0) * 100)
            }
            if (house.SettlementEventYear && (filters.Year.length === 0 || filters.Year.includes(house.SettlementEventYear))) {
                totals.Settlement.Units++
                totals.Settlement.TotalSales += house.TotalSalesPriceAmount
                totals.Settlement.Gross += house.GrossProfitAmount
                totals.Settlement.Avg = (((totals.Settlement.Gross / totals.Settlement.TotalSales) || 0) * 100)
            }


        }

        return totals
    }


    // filter data when filters change
    useEffect(() => {
        if (eventsBackup) handleData(eventsBackup)
    }, [filters, eventsBackup])



    useEffect(() => {
        if (ScenarioID) getData()
    }, [ScenarioID, triggerNewData])

    return (
        <div className="w-full  h-full gap-y-4 max-w-[1600px] mx-auto">
            {/* header */}
            <div className="w-full h-[88px] gap-6 items-center bep-shadow-xl rounded-lg p-4 flex">
                <span className=" headline-large">Projected Performance</span>
                <MultiSelectField name="Community" label="Community" selected={filters.Community} setValue={setFilters} options={options.Community} size="md" />
                <MultiSelectField name="Year" label="Year" selected={filters.Year} setValue={setFilters} options={options.Year} size="md" />
            </div>

            {/* body */}
            <div className="grid grid-cols-12 flex-grow flex-col gap-7 ">

                <div className="col-span-4 bep-shadow-xl rounded-lg p-4 h-[256px]">
                    <span>Sale Count by Status</span>
                    <PieChart type="Sale" houses={houseList} years={filters.Year} />
                </div>

                <div className="col-span-4 bep-shadow-xl rounded-lg p-4 h-[256px]">
                    <span>Start Count by Status</span>
                    <PieChart type="Start" houses={houseList} years={filters.Year} />
                </div>

                <div className="col-span-4 bep-shadow-xl rounded-lg p-4 h-[256px]">
                    <span>Settlement Count by Status</span>
                    <PieChart type="Settlement" houses={houseList} years={filters.Year} />
                </div>
            </div>
            <div className="flex-grow bep-shadow-xl rounded-lg overflow-auto mt-5 max-h-[500px]" >
                <table className="w-full h-max">
                    <thead className="sticky top-0 bg-gray-50 z-10" >
                        <tr>
                            <td></td>
                            <td colSpan={4}>Sale</td>
                            <td colSpan={4}>Start</td>
                            <td colSpan={4}>Settlement</td>
                        </tr>

                        <tr>
                            <td></td>
                            <td>Units</td>
                            <td>Total Sales</td>
                            <td>Gross</td>
                            <td>Avg %</td>

                            <td>Units</td>
                            <td>Total Sales</td>
                            <td>Gross</td>
                            <td>Avg %</td>

                            <td>Units</td>
                            <td>Total Sales</td>
                            <td>Gross</td>
                            <td>Avg %</td>
                        </tr>
                    </thead>


                    <tbody>
                        {
                            eventMap ?


                                // render the event map insde the table
                                eventMap.map(([eventStatus, communities]) => {
                                    const eventStatusTotals = calculateTotals(communities.flatMap(community => community.houses))

                                    return <Fragment key={eventStatus}>
                                        <tr className="even:bg-gray-200  odd:bg-white" key={eventStatus} onClick={() => handleClickedObject('eventStatus', eventStatus)}>
                                            <td className="hover:bg-blue-100 p-1 cursor-pointer text-left font-bold sticky left-0 bg-inherit" >{openEventStatus.includes(eventStatus) ? '-' : '+'} {eventStatus}</td>
                                            <TotalsRow totals={eventStatusTotals} />

                                        </tr>

                                        {
                                            openEventStatus?.includes(eventStatus) && communities.map(community => {
                                                const communityTotals = calculateTotals(community.houses)

                                                return <Fragment key={eventStatus + community.CommunityNameCode} >
                                                    <tr key={community.CommunityNameCode} className="even:bg-gray-200 odd:bg-white" onClick={() => handleClickedObject('community', community.CommunityNameCode)}>
                                                        <td className="hover:bg-blue-100 p-1 pl-4 cursor-pointer text-left font-bold sticky left-0 bg-inherit">{openCommunities.includes(community.CommunityNameCode) ? '-' : '+'} {community.CommunityNameCode}</td>
                                                        <TotalsRow totals={communityTotals} />
                                                    </tr>
                                                    {
                                                        openCommunities.includes(community.CommunityNameCode) && community.houses.map(house => (
                                                            <tr className="even:bg-gray-200 odd:bg-white" key={eventStatus + community.CommunityNameCode + house.HouseCode} >
                                                                <td className="hover:bg-blue-100 p-1 pl-7 cursor-pointer text-left font-bold sticky left-0 bg-inherit">{house.HouseCode}</td>

                                                                {
                                                                    house.SaleEventYear && (filters.Year.length === 0 || filters.Year.includes(house.SaleEventYear)) ? (
                                                                        <>
                                                                            <td className="table-cell"></td>
                                                                            <td className="table-cell">{Currency(house.TotalSalesPriceAmount)}</td>
                                                                            <td className="table-cell">{Currency(house.GrossProfitAmount)}</td>
                                                                            <td className="table-cell">{(house.GrossProfitAmount / house.TotalSalesPriceAmount * 100).toFixed(0)}%</td>
                                                                        </>
                                                                    )
                                                                        :
                                                                        <>
                                                                            <td className="table-cell"></td>
                                                                            <td className="table-cell"></td>
                                                                            <td className="table-cell"></td>
                                                                            <td className="table-cell"></td>

                                                                        </>
                                                                }
                                                                {
                                                                    house.StartEventYear && (filters.Year.length === 0 || filters.Year.includes(house.StartEventYear)) ? (
                                                                        <>
                                                                            <td className="table-cell"></td>
                                                                            <td className="table-cell">{Currency(house.TotalSalesPriceAmount)}</td>
                                                                            <td className="table-cell">{Currency(house.GrossProfitAmount)}</td>
                                                                            <td className="table-cell">{(house.GrossProfitAmount / house.TotalSalesPriceAmount * 100).toFixed(0)}%</td>
                                                                        </>
                                                                    )
                                                                        :
                                                                        <>
                                                                            <td className="table-cell"></td>
                                                                            <td className="table-cell"></td>
                                                                            <td className="table-cell"></td>
                                                                            <td className="table-cell"></td>

                                                                        </>
                                                                }
                                                                {
                                                                    house.SettlementEventYear && (filters.Year.length === 0 || filters.Year.includes(house.SettlementEventYear)) ? (
                                                                        <>
                                                                            <td className="table-cell"></td>
                                                                            <td className="table-cell">{Currency(house.TotalSalesPriceAmount)}</td>
                                                                            <td className="table-cell">{Currency(house.GrossProfitAmount)}</td>
                                                                            <td className="table-cell">{(house.GrossProfitAmount / house.TotalSalesPriceAmount * 100).toFixed(0)}%</td>
                                                                        </>
                                                                    )
                                                                        :
                                                                        <>
                                                                            <td className="table-cell"></td>
                                                                            <td className="table-cell"></td>
                                                                            <td className="table-cell"></td>
                                                                            <td className="table-cell"></td>

                                                                        </>
                                                                }
                                                            </tr>
                                                        ))
                                                    }
                                                </Fragment>

                                            })
                                        }
                                    </Fragment>
                                })

                                :

                                <tr><td className="text-center" colSpan={14}>{ScenarioID ? "Loading..." : "Please select a scenario"}</td></tr>
                        }

                        {
                            eventMap ? (
                                <tr className="sticky bottom-0 z-30 even:bg-gray-200  odd:bg-white">
                                    <td className="hover:bg-blue-100 p-1 pl-4 cursor-pointer text-left font-bold sticky left-0 bg-inherit">Totals</td>
                                    <TotalsRow totals={calculateTotals(houseList)} />
                                </tr>
                            ) :
                                <></>
                        }
                    </tbody>

                </table>
            </div>


        </div>
    )
}


function TotalsRow({ totals }: { totals: any }) {
    return (
        <>
            <td className="table-cell font-bold">{totals.Sale.Units}</td>
            <td className="table-cell font-bold">{Currency(totals.Sale.TotalSales)}</td>
            <td className="table-cell font-bold">{Currency(totals.Sale.Gross)}</td>
            <td className="table-cell font-bold">{totals.Sale.Avg.toFixed(0)}%</td>

            <td className="table-cell font-bold">{totals.Start.Units}</td>
            <td className="table-cell font-bold">{Currency(totals.Start.TotalSales)}</td>
            <td className="table-cell font-bold">{Currency(totals.Start.Gross)}</td>
            <td className="table-cell font-bold">{totals.Start.Avg.toFixed(0)}%</td>

            <td className="table-cell font-bold">{totals.Settlement.Units}</td>
            <td className="table-cell font-bold">{Currency(totals.Settlement.TotalSales)}</td>
            <td className="table-cell font-bold">{Currency(totals.Settlement.Gross)}</td>
            <td className="table-cell font-bold">{totals.Settlement.Avg.toFixed(0)}%</td>
        </>
    )
}


