import { useMemo, useState } from "react";
import Button from "../../components/atoms/Button";
import Modal from "../../components/atoms/Modal";
import Report from "./Report";
import CheckMarkProgress from "./CheckMarkProgress";
import { GeneralPage1, GeneralPage2, SchedulePage, RevenuePage, CostingPage1, CostingPage2, CostingPage3, CapitalPage, CompletePage } from "./Pages";
import { JVSpecDefaultValues, type JVSpec } from "./JVSpecTypes"


type PageNumberType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

const progessBarNextThreshholds = [2, 3, 4, 7, 8]
const progressBarPreviousThreshholds = [1, 2, 3, 6, 7]

type ProgressType = {
    section: number,
    page: PageNumberType
}

export default function JVSpec() {
    const [progress, setProgress] = useState<ProgressType>({ section: 0, page: 0 });
    const [isNextValid, setIsNextValid] = useState(false);
    const [form, setForm] = useState<JVSpec>(JVSpecDefaultValues)
    const [triggerCalculations, setTriggerCalculations] = useState(false)
    const [isReportOpen, setIsReportOpen] = useState(false)
    const triggerChange = () => setTriggerCalculations(!triggerCalculations)
    const componentsMap = useMemo(() => {
        return {
            0: <GeneralPage1 setIsNextValid={setIsNextValid} />,
            1: <GeneralPage2 form={form} setForm={setForm} setIsNextValid={setIsNextValid} />,
            2: <SchedulePage form={form} setForm={setForm} setIsNextValid={setIsNextValid} triggerCalculations={triggerChange} />,
            3: <RevenuePage form={form} setForm={setForm} setIsNextValid={setIsNextValid} triggerCalculations={triggerChange} />,
            4: <CostingPage1 form={form} setForm={setForm} setIsNextValid={setIsNextValid} triggerCalculations={triggerChange} />,
            5: <CostingPage2 form={form} setForm={setForm} setIsNextValid={setIsNextValid} triggerCalculations={triggerChange} />,
            6: <CostingPage3 form={form} setForm={setForm} setIsNextValid={setIsNextValid} triggerCalculations={triggerChange} />,
            7: <CapitalPage form={form} setForm={setForm} setIsNextValid={setIsNextValid} triggerCalculations={triggerChange} />,
            8: <CompletePage />
        }
    }, [form, setForm, progress]);

    const handleNext = () => {
        setProgress((prev: ProgressType) => {
            const newPage = (prev.page + 1) as PageNumberType;
            const newSection = progessBarNextThreshholds.includes(newPage) ? prev.section + 1 : prev.section;
            return { section: newSection, page: newPage };
        });
    }

    const handlePrevious = () => {
        setProgress((prev: ProgressType) => {
            const newPage = (prev.page - 1) as PageNumberType;
            const newSection = progressBarPreviousThreshholds.includes(newPage) ? prev.section - 1 : prev.section;
            return { section: newSection, page: newPage };
        })
    }

    return (
        <div className="flex w-full h-full">
            <main className=" h-full flex-grow m-auto max-w-[1140px] overflow-auto py-10 ">
                <header className="grid grid-cols-2 w-[580px] mx-auto h-[144px] flex-wrap">
                    <h1 className="flex title-large items-center ">
                        Joint Venture Spec Tool
                    </h1>

                    <aside className="flex w-full 2xl:hidden justify-end">
                        <Button label="Report" action={() => setIsReportOpen(true)} styleType="tonal" type="button" />
                    </aside>

                    <div className="w-full col-span-2 justify-center flex ">
                        <CheckMarkProgress key='CheckMarkProgress' progress={progress.section} steps={["General", "Schedule", "Revenue", "Costs", "Capital"]} />
                    </div>

                </header>

                <form className="w-[580px] mx-auto" >
                    {componentsMap[progress.page] || <div>Error</div>}
                </form>

                <section className=" flex mx-auto w-[580px] pt-10">
                    <span className="m-auto ml-0">
                        {progress.page > 0 && <Button label="Previous" action={handlePrevious} styleType="tonal" type="button" />}
                    </span>

                    <span className="m-auto mr-0">
                        {progress.page < 8 && <Button label="Next" action={handleNext} styleType="tonal" disabled={!isNextValid} type="button" />}
                    </span>
                </section>

            </main>


            <aside className=" hidden 2xl:flex flex-grow h-full w-full max-w-[700px] overflow-auto p-8 bg-gray-100">
                <Report form={form} triggerCalculations={triggerCalculations} setForm={setForm} triggerChange={triggerChange} />
            </aside>

            {
                isReportOpen && (
                    <Modal
                        className="w-full max-w-[720px] p-10 m-20 max-h-[700px] h-full bg-gray-100 overflow-auto rounded-lg"
                        onClose={() => setIsReportOpen(false)}>
                        <Report form={form} triggerCalculations={triggerCalculations} setForm={setForm} triggerChange={triggerChange} />
                    </Modal>)
            }
        </div>
    )
}
