import { useEffect, useState } from "react";
import Search from "../../components/molecules/Search";
import { useLocation } from "react-router-dom";
import APIRequest from "../../helpers/CreateRequest";
import TextField from "../../components/atoms/forms/Fields/TextField";
import { FieldValues, useForm } from "react-hook-form";
import Button from "../../components/atoms/Button";
import { PublicClientApplication } from "@azure/msal-browser";
import SelectField from "../../components/atoms/forms/Fields/SelectField";

const tenantID = process.env.REACT_APP_TENANT_ID || ''
const appClientID = process.env.REACT_APP_APP_CLIENT_ID || ''
const groupID = process.env.REACT_APP_GROUP_ID || ''
const datasetID = process.env.REACT_APP_DATASET_ID || ''

const POWERBIREPORT = process.env.REACT_APP_ENV === "PROD" ? <iframe title="Analytics" className="w-full h-full" src="https://app.powerbi.com/reportEmbed?reportId=da143a66-dee6-490a-833c-ec1ca886ea69&autoAuth=true&ctid=b886e5e8-64d0-4f1a-8c56-dee8e3c912a7" frameBorder="0" allowFullScreen={true}></iframe>
    : process.env.REACT_APP_ENV === 'UAT' ? <iframe title="Analytics" className="w-full h-full" src="https://app.powerbi.com/reportEmbed?reportId=53aeb01d-9f35-463e-87e2-36c0c372160b&autoAuth=true&ctid=b886e5e8-64d0-4f1a-8c56-dee8e3c912a7" frameBorder="0" allowFullScreen={true}></iframe>
        : <iframe title="Analytics" className="w-full h-full" src="https://app.powerbi.com/reportEmbed?reportId=4a12b50b-f70b-426e-86ee-c256dcd8b8cb&autoAuth=true&ctid=b886e5e8-64d0-4f1a-8c56-dee8e3c912a7" frameBorder="0" allowFullScreen={true}></iframe>


const redirectUri = process.env.REACT_APP_ENV === "PROD" ? 'https://www.buildexecpro.com/blank.html'
    : process.env.REACT_APP_ENV === 'UAT' ? 'https://bss-bep-uat-app-ui-as-1-eus.azurewebsites.net/blank.html'
        : process.env.REACT_APP_ENV === 'TEST' ? 'https://bss-bep-test-app-ui-as-1-eus.azurewebsites.net/blank.html'
            : "http://localhost:3000/blank.html"


export default function Reports() {
    const location = useLocation()
    const stateID = location.state?.id || undefined
    const [scenarioID, setScenarioID] = useState(stateID)
    const [searchOptions, setSearchOptions] = useState<{ name: string, value: number }[]>([])
    const [accessToken, setAccessToken] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [reportReady, setReportReady] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { isSubmitting },
        getValues,
        setValue
    } = useForm()


    async function GetSearchOptions() {
        try {
            const result = await new APIRequest('/scenarios/names', 'GET', null, null).GenerateRequest()
            const body = await result.json()
            if (body.status === 200) {
                const recordset = body.recordset
                let tempScenarioOptions: { name: string, value: number }[] = []

                for (let i = 0; i < recordset.length; i++) {
                    tempScenarioOptions.push({ name: recordset[i]['ScenarioNameCode'], value: recordset[i]['ScenarioID'] })
                }
                setSearchOptions(tempScenarioOptions)
            } else {
                console.log('bad')
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function UserAuthentication() {
        const msalConfig = {
            auth: {
                clientId: appClientID, // 'your_client_id'
                authority: `https://login.microsoftonline.com/${tenantID}`
            }
        };

        const msalInstance = new PublicClientApplication(msalConfig);
        await msalInstance.initialize();

        let accessToken = ''

        try {
            const loginResponse = await msalInstance.loginPopup({
                scopes: [
                    "https://analysis.windows.net/powerbi/api/.default" // .default includes all permissions defined in the application registration
                ],
                prompt: "consent", // Include prompt parameter for consent
                redirectUri: redirectUri,
            });

            accessToken = loginResponse.accessToken
            if (accessToken) setAccessToken(accessToken)

        } catch (error) {
            console.error("Error during login:", error);
        }
    }

    async function GetAccessToken() {
        try {
            const msalConfig = {
                auth: {
                    clientId: appClientID, // 'your_client_id'
                    authority: `https://login.microsoftonline.com/${tenantID}`
                }
            };

            const msalInstance = new PublicClientApplication(msalConfig);
            await msalInstance.initialize();

            const account = msalInstance.getAllAccounts()[0];
            if (account) {
                const tokenRequest = {
                    scopes: ["https://analysis.windows.net/powerbi/api/.default"],
                    account: account
                };

                let response;
                response = await msalInstance.acquireTokenSilent(tokenRequest);
                setAccessToken(response.accessToken)
            } else {
                UserAuthentication()
            }
        } catch (err) {
            console.error(err);
        }
    }

    async function DecodeToken() {
        try {
            let decodedToken: any;
            const result = await new APIRequest('/auth/decode-token', 'GET', null, null).GenerateRequest()
            decodedToken = await result.json()
            return decodedToken
        } catch (err) {
            console.error('Error getting id values: ' + err)
            return false
        }
    }

    async function GenerateScenarios(e: FieldValues) {
        try {
            const result = await new APIRequest('/reports/scenariosGenerateEvents', "POST", null, {
                ScenarioID: scenarioID,
                SysReportID: e.SysReportID
            }).GenerateRequest()

            if (result.status === 200) return true
            else throw `Failed to generate scenarios`
        } catch (err) {
            console.error(err)
            return false
        }
    }

    async function UpdateParameters(decodedToken: { TenantID: string, SessionID: string, UserID: string }) {
        try {
            const result = await fetch(`https://api.powerbi.com/v1.0/myorg/groups/${groupID}/datasets/${datasetID}/Default.UpdateParameters`, {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    updateDetails: [
                        {
                            name: "ScenarioID",
                            newValue: scenarioID
                        },
                        {
                            name: 'TenantID',
                            newValue: decodedToken.TenantID
                        },
                        {
                            name: 'SessionID',
                            newValue: decodedToken.SessionID
                        },
                        {
                            name: 'UserID',
                            newValue: decodedToken.UserID
                        }
                    ]
                })
            })
            if (result && result.status === 200) return true
            else throw 'Error updating parameters'

        } catch (err) {
            console.error(err)
            return false
        }
    }

    async function RefreshDataset() {
        try {
            const result = await fetch(`https://api.powerbi.com/v1.0/myorg/datasets/${datasetID}/refreshes`, {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                }
            })

            if (result.status === 200 || result.status === 202) {
                return true
            } else {
                if (result.status === 429) {
                    setErrorMessage('Maximum refreshes reached. Must manually refresh inside PowerBI app')
                }
                throw 'Failure during refresh'
            }
        } catch (err) {
            console.error(err)
            return false
        }
    }


    const onSubmit = async (e: FieldValues) => {
        setReportReady(false)
        //get tenant, user, and session id and return if fail
        const decodedToken = await DecodeToken()
        if (!decodedToken) return

        // generate scenariosand return if fail
        const generatedScenarios = await GenerateScenarios(e)
        if (!generatedScenarios) return

        //Update parameters and return if fail
        const updatedParameters = await UpdateParameters(decodedToken)
        if (!updatedParameters) return

        //refresh dataset and if successful wait 5 seconds to let powerbi process data (not using that way since refresh limit causing issues)
        const refreshDataset = await RefreshDataset()

        //wait for powerbi to complete refresh 
        setTimeout(() => {
            setReportReady(true)
        }, 5000);
    }


    useEffect(() => { GetAccessToken() }, [])

    useEffect(() => { GetSearchOptions() }, [])

    // Set scenario name field to name selected
    useEffect(() => {
        for (let i = 0; i < searchOptions.length; i++) {
            if (searchOptions[i].value === scenarioID) setValue('Scenario', searchOptions[i].name)
        }
    }, [scenarioID])

    const getScenarioName = () => {
        for (let i = 0; i < searchOptions.length; i++) {
            if (searchOptions[i].value === scenarioID) return searchOptions[i].name
        }

    }

    return (
        // container
        <div className="px-20 py-[60px] h-full w-full">
            <div className="font-bold text-xl mb-8">
                <span>Forecasting</span>
                <span> {">"} </span>
                <span>Reports</span>
            </div>

            {/* Form */}
            <form className="flex w-[700px] h-[250px] gap-6" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col w-[400px] gap-y-6">
                    <SelectField
                        name="SysReportID"
                        label="Report Type"
                        value={getValues('SysReportID')}
                        required={true}
                        setValue={setValue}
                        fieldNames={{ name: 'SysReportID', namePlural: 'SysReportIDs' }}
                        staticOptions={[
                            {
                                name: "Analytics",
                                value: 1
                            },
                        ]}
                    />

                    {/* Search */}
                    <Search
                        options={searchOptions}
                        setID={setScenarioID}
                    />

                    {/* Scenario Name */}
                    <TextField
                        name="Scenario"
                        label="Scenario name"
                        value={getScenarioName()}
                        formRef={register('Scenario')}
                        setValue={setValue}
                        required={true}
                        readonly={true}
                        
                    />
                </div>


                <div className="flex flex-col">
                    {/* Preview Button */}
                    <Button
                        label="Preview"
                        styleType="tonal"
                        type="submit"
                        action={() => null}
                        disabled={!scenarioID}
                    />
                </div>

                <div className="flex flex-col h-full label-large">
                    <p>*refresh page if table data does not change</p>
                    <p className=" text-md-error">{errorMessage}</p>
                </div>

            </form>

            <div className="h-full w-full">
                {!isSubmitting && reportReady && POWERBIREPORT}
            </div>
        </div>
    )
}