import { FormEvent, useEffect, useRef, useState } from "react"
import SignInContainer from "./SignInContainer"
import { useNavigate } from "react-router-dom"
import APIRequest from "../../helpers/CreateRequest"
import { useCookies } from "react-cookie";
import Input from "./Input"
import Button from "./Button"

export default function ForgotPassword() {
    const [cookies, setCookie, removeCookie] = useCookies();
    const [form, setForm] = useState({ Email: '', Code: "" })
    const [emailSent, setEmailSent] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [code, setCode] = useState(Array(6).fill(""));
    const inputsRef = useRef<HTMLInputElement[]>([]);
    const [attemptsRemaining, setAttemptsRemaining] = useState()
    const navigate = useNavigate()

    const handleEmailSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            const result = await (new APIRequest('/auth/password-reset-validate-and-send-auth-code', 'POST', null, form,).GenerateRequest())
            if (result.status !== 200) throw result.statusText
            const body = await result.json()
            if (body.status !== 200) throw body.message
            setCookie('resetToken', body.token, {path: "/", maxAge: 1800}) // Expires in 30 minutes
            setEmailSent(true)
        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    const handleCodeSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)
        setIsError(false)
        try {
            const Code = code.join('')
            const result = await (new APIRequest('/auth/password-reset-validate-auth-code', 'POST', null, { Code: Code }, 'resetPassword').GenerateRequest())
            if (result.status !== 200) throw result.statusText
            const body = await result.json()
            if (body.status !== 200) {
                setAttemptsRemaining(body.attemptsRemaining)
                throw body.message
            }
            navigate('/reset-password', { state: { Email: form.Email, Code: Code } }) // TODO: add key when navigating
        } catch (err) {
            setIsError(true)
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = e.target;
        if (/^[a-zA-Z0-9]*$/.test(value)) {  // Only allow alphanumeric input
            const newCode = [...code];
            newCode[index] = value.toUpperCase().slice(-1);  // Only keep the last character typed
            setCode(newCode);
            setIsError(false);
            // Move focus to the next input
            if (value && index < 5) {
                inputsRef.current[index + 1].focus();
            }
        }
    };

    const handleBackspace = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === "Backspace" && !code[index] && index > 0) {
            // Move focus to the previous input on backspace
            inputsRef.current[index - 1].focus();
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData("text").toUpperCase().slice(0, 6);
        if (/^[a-zA-Z0-9]*$/.test(pasteData)) {
            const newCode = pasteData.split("");
            setCode([...newCode, ...Array(6 - newCode.length).fill("")]); // Fill remaining with empty values
            newCode.forEach((char, index) => {
                if (inputsRef.current[index]) {
                    inputsRef.current[index].value = char;
                }
            });
            if (newCode.length < 6) {
                inputsRef.current[newCode.length]?.focus(); // Focus the next field if the paste doesn't fill all fields
            }
        }
    };


    useEffect(() => {
        // if the code is full then submit the form
        if (code.join('').length === 6) {
            const form = document.getElementById('CodeValidationForm') as HTMLFormElement
            if (form) form.requestSubmit()
        }
    }, [code])

    return (
        <SignInContainer>
            <>
                {
                    emailSent ?
                        <form id="CodeValidationForm" onSubmit={handleCodeSubmit} className="flex flex-col gap-y-3 items-center">
                            <h1 className="font-semibold text-2xl mt-2">Check your email for a code</h1>

                            <p className="w-[350px]">
                                Enter the reset code sent to <strong>{form.Email}</strong>
                            </p>
                            <div className="flex space-x-2 justify-center items-center font-semibold text-xl">
                                {code.map((value, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        maxLength={1}
                                        value={value}
                                        onChange={(e) => handleInputChange(e, index)}
                                        onKeyDown={(e) => handleBackspace(e, index)}
                                        onPaste={handlePaste}
                                        ref={(el) => inputsRef.current[index] = el!}
                                        className="w-[50px] h-[50px] text-center border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                        autoFocus={index === 0}
                                    />
                                ))}
                            </div>

                            {
                                isError && !isLoading && 
                                <div className="bg-red-100 rounded-lg w-[340px] p-2">
                                    <div className="flex">
                                        <div className="flex items-center justify-center pl-4 pr-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ef4444"><path d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z" /></svg>
                                        </div>
                                        <div className="flex flex-col justify-center items-center pl-4">
                                            <div className="flex items-center justify-center flex-nowrap">The code entered is not valid.</div>
                                            <div className="flex items-center justify-center flex-nowrap">{attemptsRemaining} attempts remaining.</div>
                                        </div>
                                    </div>
                                </div>

                            }

                        </form>

                        :
                        <form onSubmit={handleEmailSubmit} className="flex flex-col gap-y-3 items-center">
                            <h1 className="font-semibold text-2xl mt-2">Forgot your password?</h1>
                            <div className="w-[350px]">
                                Enter your email to receive a reset code.
                            </div>
                            <Input
                                placeholder="Email"
                                type="email"
                                name="Email"
                                onChange={(e) => setForm(prev => { return { ...prev, Email: e.target.value } })}
                                autoFocus
                            />

                            <Button text="Send reset code" disabled={!form.Email} isLoading={isLoading} />
                        </form>
                }
            </>
        </SignInContainer>
    )
}