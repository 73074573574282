import { ResponsivePie } from "@nivo/pie"
import { useEffect, useState } from "react"
import { multiEventTypeHouse } from "./ProjectedPerformanceReport"


const ACTUAL = 0
const ESTIMATED = 1
const SCHEDULED = 2

export default function PieChart({ type, houses, years }: { type: "Sale" | "Start" | "Settlement", houses: multiEventTypeHouse[], years: string[] }) {
    const [data, setData] = useState<any[]>([])


    // Sale count by status 
    useEffect(() => {
        const tempData = [
            {
                id: "Actual",
                label: "Actual",
                value: 0,
                color: "hsl(0, 70%, 50%)"
            },
            {
                id: "Estimated",
                label: "Estimated",
                value: 0,
                color: "hsl(120, 70%, 50%)"
            },
            {
                id: "Scheduled",
                label: "Scheduled",
                value: 0,
                color: "hsl(240, 70%, 50%)"
            }
        ]

        for (let i = 0; i < houses.length; i++) {
            const house = houses[i]
            const year = type === "Sale" ? house.SaleEventYear : type === "Start" ? house.StartEventYear : house.SettlementEventYear
            if (year && (years.length === 0 || years.includes(year))) {
                if (house.EventStatus === "Actual") tempData[ACTUAL].value++
                else if (house.EventStatus === "Estimated") tempData[ESTIMATED].value++
                else tempData[SCHEDULED].value++
            }
        }

        setData(tempData)

    }, [houses])


    return (
        <ResponsivePie
            data={data}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={6}
            borderWidth={1}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.2
                    ]
                ]
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        2
                    ]
                ]
            }}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}
            fill={[
                {
                    match: {
                        id: 'ruby'
                    },
                    id: 'dots'
                },
                {
                    match: {
                        id: 'c'
                    },
                    id: 'dots'
                },
                {
                    match: {
                        id: 'go'
                    },
                    id: 'dots'
                },
                {
                    match: {
                        id: 'python'
                    },
                    id: 'dots'
                },
                {
                    match: {
                        id: 'scala'
                    },
                    id: 'lines'
                },
                {
                    match: {
                        id: 'lisp'
                    },
                    id: 'lines'
                },
                {
                    match: {
                        id: 'elixir'
                    },
                    id: 'lines'
                },
                {
                    match: {
                        id: 'javascript'
                    },
                    id: 'lines'
                }
            ]}
            legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 56,
                    itemsSpacing: 0,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: '#999',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000'
                            }
                        }
                    ]
                }
            ]}
        />
    )
}