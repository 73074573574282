import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProductMain from "./pages/ProductMain/ProductMain";
import AppMain from "./pages/AppMain/AppMain";
import Private from "./layout/Private";
import Public from "./layout/Public";
import SignIn from "./pages/SignIn/SignIn";
import Layout from "./layout/Layout";
import BusinessUnits from "./pages/BusinessUnits/BusinessUnits";
import Divisions from "./pages/Divisions/Divisions";
import Roles from "./pages/Roles/Roles";
import Procedures from './pages/Procedures/Procedures'
import Departments from "./pages/Departments/Departments";
import Positions from "./pages/Positions/Positions";
import BusinessPlans from "./pages/BusinessPlans/BusinessPlans";
import Developments from "./pages/Developments/Developments";
import Concepts from "./pages/Concepts/Concepts";
import Communities from "./pages/Communities/Communities";
import SalesPlans from "./pages/SalesPlans/SalesPlans";
import Budgets from "./pages/Budgets/Budgets";
import Scenarios from './pages/Scenarios/Scenarios'
import Reports from "./pages/Reports/Reports";
import Regions from "./pages/Regions/Regions";
import Territories from "./pages/Territories/Territories";
import HousesBacklog from "./pages/HousesBacklog/HousesBacklog";
import SalesPlanHouses from "./pages/SalesPlans/Houses/SalesPlanHouses";
import Employees from "./pages/Employees/Employees";
import DataManagement from "./pages/DataManagement/DataManagement";
import SalesForecasts from "./pages/SalesForecasts/SalesForecasts";
import SalesForecastHouses from "./pages/SalesForecasts/SalesForecastHouses";
import JVSpec from "./pages/JVSpec/JVSpec";
import SignUp from "./pages/SignIn/SignUp";
import ResetPassword from "./pages/SignIn/ResetPassword";
import ForgotPassword from "./pages/SignIn/ForgotPassword";

function App(): JSX.Element {

  return (
    <BrowserRouter>
      <Routes>
        {/* OPEN ROUTES */}
        <Route path="/" element={<ProductMain />} />

        {/* PRIVATE ROUTES */}
        <Route element={<Private />}>
          <Route element={<Layout />} >
            <Route path="/app/home" element={<AppMain />} />


            <Route path="/app/business-plans" element={<BusinessPlans />} />
            <Route path="/app/scenarios" element={<Scenarios />} />
            <Route path="/app/reports" element={<Reports />} />


            <Route path="/app/regions" element={<Regions />} />
            <Route path="/app/territories" element={<Territories />} />
            <Route path="/app/sales-plans" element={<SalesPlans />} />
            <>
              <Route path="/app/sales-plans/houses" element={<SalesPlanHouses />} />
            </>
            <Route path="/app/sales-forecasts" element={<SalesForecasts />} />
            <>
              <Route path="/app/sales-forecasts/houses" element={<SalesForecastHouses />} />
            </>
            <Route path="/app/joint-venture-spec-tool" element={<JVSpec />} />

            <Route path="/app/communities" element={<Communities />} />
            <Route path="/app/houses-backlog" element={<HousesBacklog />} />
            <Route path="/app/budgets" element={<Budgets />} />


            <Route path="/app/developments" element={<Developments />} />
            <Route path="/app/concept-plans" element={<Concepts />} />

            <Route path="/app/positions" element={<Positions />} />
            <Route path="/app/roles" element={<Roles />} />
            <Route path="/app/procedures" element={<Procedures />} />


            <Route path="/app/business-units" element={<BusinessUnits />} />
            <Route path="/app/divisions" element={<Divisions />} />
            <Route path="/app/departments" element={<Departments />} />
            <Route path="/app/employees" element={<Employees />} />


            {/* Data Management */}
            <Route path="/app/data-management" element={<DataManagement />} />



          </Route>
        </Route>

        {/* PUBLIC-ONLY ROUTES */}
        <Route element={<Public />}>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
