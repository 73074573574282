import { useState } from "react"
import { UseFormRegisterReturn, UseFormSetValue } from "react-hook-form"
import BaseField from "./BaseField"

export type TextFieldType = {
    name: string,
    label: string,
    size?: 'sm' | 'md' | 'lg' | 'xl',
    supportingText?: string,
    disabled?: boolean,
    required?: boolean,
    value: string | undefined,
    formRef: UseFormRegisterReturn<string>,
    setValue: UseFormSetValue<any>
    readonly?: boolean,
    setWrapperState?: Function
    hidden?: boolean
}

export default function TextField(props: TextFieldType) {
    const [isFocused, setIsFocused] = useState(false)
    const [tempValue, setTempValue] = useState(props.value || '')

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.formRef.onChange(e)
        props.setValue(props.name, e.target.value, { shouldDirty: true })

        setTempValue(e.target.value)
        if (props.setWrapperState) props.setWrapperState(e.target.value)
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        props.formRef.onBlur(e)
        setIsFocused(false)
    }

    const handleFocus = () => {
        setIsFocused(true)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {

    }

    return (
        <BaseField
            {...props}
            type="text"
            handleChange={handleChange}
            handleFocus={handleFocus}
            handleKeyDown={handleKeyDown}
            handleBlur={handleBlur}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            tempValue={tempValue}
            setTempValue={setTempValue}
            
        />
    )
}