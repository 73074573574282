import { useEffect, useState } from "react";

const testimonials = [
    {
        quote: "BuildExecPro has completely changed the way we manage our projects.",
        name: "John Doe",
        title: "ABC Construction",
        image: "https://via.placeholder.com/100x100",
    },
    {
        quote: "The AI insights helped us make faster and more informed decisions.",
        name: "Jane Smith",
        title: "Smith Builders",
        image: "https://via.placeholder.com/100x100",
    },
    {
        quote: "With BuildExecPro, generating reports and managing data is seamless.",
        name: "Chris Johnson",
        title: "Future Homes",
        image: "https://via.placeholder.com/100x100",
    },
    {
        quote: "We've seen a 30% increase in productivity since switching to BuildExecPro.",
        name: "Sarah Brown",
        title: "Brown Construction",
        image: "https://via.placeholder.com/100x100",
    }
];

export default function TestimonialCarousel() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false)
    const [isHovering, setIsHovering] = useState(false)
    const [counter, setCounter] = useState(0)

    // const nextSlide = () => {
    //     if (!isLoading) {
    //         setIsLoading(true)
    //         setCurrentIndex((prevIndex) =>
    //             prevIndex + 1
    //         );
    //     }
    // };

    // const prevSlide = () => {
    //     if (!isLoading) {
    //         setIsLoading(true)
    //         setCurrentIndex((prevIndex) =>
    //             prevIndex - 1
    //         );
    //     }
    // };

    // sets isLoading to false after transitions are complete (700ms) this is to ensure a smooth transition even if the user clicks the next/prev button multiple times
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 700)
    }, [isLoading])

    // auto move index
    useEffect(() => {
        if (!isHovering && counter % 5 === 0) {
            setCurrentIndex((prevIndex) =>
                prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
            );
        }
    }, [counter])

    // counter for auto move index
    useEffect(() => {
        const interval = setInterval(() => {
            setCounter((prev) => prev > 999999 ? 0 : prev + 1)
        }, 1000)
        return () => clearInterval(interval)
    }, [])


    return (
        <>
            <div className="flex max-w-[1200px] m-auto" onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} onTouchStart={() => setIsHovering(true)} onTouchEnd={() => setIsHovering(false)}>
                {/* Previous Arrow */}
                {/* <button
                    disabled={currentIndex === 0}
                    onClick={prevSlide}
                    className="bg-sky-500 text-white p-4 rounded-full hover:bg-sky-600 transition-all disabled:bg-gray-300 disabled:cursor-default shadow-lg hover:shadow-xl transform hover:scale-105 disabled:scale-100 m-auto"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="rotate-90" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" /></svg>
                </button> */}


                {/* Testimonial Slides */}
                <div className="max-w-[900px] overflow-hidden m-auto ">
                    <div
                        className={`flex transition-transform duration-700 my-4 w-full`}
                        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                    >
                        {testimonials.map((testimonial, index) => (
                            <div
                                key={index}
                                className="flex-shrink-0 w-full px-4"
                            >
                                <div className={`bg-white shadow-lg gap-x-5 rounded-xl p-6 text-center flex items-center transition-all hover:scale-[102%] duration-500 ${currentIndex === index ? 'opacity-100' : 'opacity-0'}`}>
                                    <img
                                        src={testimonial.image}
                                        alt={testimonial.name}
                                        className="w-[100px] h-[100px] rounded-full mb-4 "
                                    />

                                    <div className="w-full">
                                        <p className="italic text-gray-600 mb-4">
                                            "{testimonial.quote}"
                                        </p>

                                        <div className="flex flex-nowrap m-auto mr-0 mb-0 text-nowrap gap-x-2 items-end w-max">
                                            <h4 className="font-bold text-sky-500">{testimonial.name}</h4>
                                            <span>-</span>
                                            <span className="text-gray-500">{testimonial.title}</span>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Next Arrow */}
                {/* <button
                    disabled={currentIndex === testimonials.length - 1}
                    onClick={nextSlide}
                    // className="bg-sky-500 text-white p-3 rounded-full hover:bg-sky-600 transition-all disabled:bg-gray-200 m-auto ml-0 "
                    className="bg-sky-500 text-white p-4 rounded-full hover:bg-sky-600 transition-all disabled:bg-gray-300 disabled:cursor-default shadow-lg hover:shadow-xl transform hover:scale-105 disabled:scale-100 m-auto"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="-rotate-90" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" /></svg>
                </button> */}
            </div>
        </>
    );
}
