import { Link } from "react-router-dom";
import { useRef, useEffect, useState } from "react";
import SignInContainer from "./SignInContainer";
import Input from "./Input";
import Button from "./Button";
import APIRequest from "../../helpers/CreateRequest";
import VerifyEmail from "./VerifyEmail";
import { Cookies, useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

type signup = {
    Email: string;
    Password: string;
    ConfirmPassword: string;
    ReferralCode: string
}

const signupDefaults: signup = {
    Email: "",
    Password: "",
    ConfirmPassword: "",
    ReferralCode: ""
}


export default function SignUp() {
    const [cookies, setCookie, removeCookie] = useCookies();
    const [form, setForm] = useState(signupDefaults)
    const [emailSent, setEmailSent] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState<string | false>(false)
    const [signUpSucceess, setSignUpSuccess] = useState(false)
    const [emailVerified, setEmailVerified] = useState(false)

    const handleSignUpSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault()
        setIsError(false)
        try {
            setIsLoading(true)
            if (form.Password !== form.ConfirmPassword) throw "Passwords do not match"
            const result = await new APIRequest('/auth/sign-up-validate-and-send-auth-code', 'POST', null, form, 'signUp').GenerateRequest()
            if (result.status !== 200) throw result.statusText
            const body = await result.json()
            if (body.status !== 200) throw body.message
            setEmailSent(true)
            setCookie('signUpToken', body.token, {path: '/', maxAge: 1800}) // Expires in 30 minutes
        } catch (err) {
            console.log(err)
            setIsError(String(err))
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {

        // Cleanup when component unmounts
        return () => {
            removeCookie('signUpToken', { path: "/" });
        };
    }, []); 

    return (
        <SignInContainer>
            {
                emailSent && emailVerified ?

                    <div>
                        <h1 className="font-semibold text-2xl mt-2">Sign up successful</h1>

                        <p className="w-[350px] mt-3">
                            <Link to="/sign-in" className="text-sky-500 font-semibold">Click here</Link> to sign in
                        </p>
                    </div>

                    :

                    emailSent && !emailVerified ?

                        <VerifyEmail setEmailVerified={setEmailVerified} email={form.Email} />

                        :

                        <form className="flex flex-col gap-y-2 items-center" onSubmit={handleSignUpSubmit}>
                            <h1 className="font-bold text-2xl mt-2">Sign up</h1>
                            <Input placeholder="Referral Code" type="text" name="ReferralCode" onChange={(e) => setForm(prev => { return { ...prev, ReferralCode: e.target.value } })} />
                            <Input placeholder="Email" type="email" name="Email" onChange={(e) => setForm(prev => { return { ...prev, Email: e.target.value } })} />
                            <Input placeholder="Password" type="password" name="Password" onChange={(e) => setForm(prev => { return { ...prev, Password: e.target.value } })} />
                            <Input placeholder="Confirm Password" type="password" name="ConfirmPassword" onChange={(e) => setForm(prev => { return { ...prev, ConfirmPassword: e.target.value } })} />
                            {isError && !isLoading && <span className="bg-red-100 rounded-lg w-[340px] px-5 py-2 flex flex-nowrap">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ef4444"><path d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z" /></svg>
                                <p className="text-sm m-auto">{isError}</p>
                            </span>}
                            <div className="mt-1">
                                <Button text="Sign up" disabled={!form.ReferralCode || !form.Email || !form.Password || !form.ConfirmPassword} isLoading={isLoading} />
                            </div>

                            <div className="flex flex-col gap-y-5 mt-1">
                                <span>Don't have a referral code? <Link to="/#pricing" state={'GetQuote'} className="text-sky-500 hover:text-sky-600">Click here</Link></span>
                            </div>
                        </form>
            }
        </SignInContainer>
    )
}