import { useState } from "react"
import { UseFormRegisterReturn, UseFormSetValue } from "react-hook-form"
import BaseField from "./BaseField"

export type NumberFieldType = {
    name: string,
    label: string,
    size?: 'sm' | 'md' | 'lg' | 'xl',
    supportingText?: string,
    disabled?: boolean,
    required?: boolean,
    value: string | undefined,
    formRef: UseFormRegisterReturn<string>
    setValue: UseFormSetValue<any>
    setWrapperState?: Function,
    readonly?: boolean
}

export default function NumberField(props: NumberFieldType) {
    const [isFocused, setIsFocused] = useState(false)
    const [tempValue, setTempValue] = useState('')

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const EXCEPTION = e.ctrlKey || e.altKey || e.metaKey || e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab' || (e.key === '.' && !e.currentTarget.value.includes('.'))

        //only digits and the excepts above will work
        if (!/^\d$/.test(e.key) && !EXCEPTION || e.key === ' ') {
            e.preventDefault()
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.formRef.onChange(e)
        if (e.target.value === '') {
            setTempValue('')
            props.setValue(props.name, '', { shouldDirty: true })

            if (props.setWrapperState) props.setWrapperState(undefined)
        } else {
            setTempValue(!e.target.value ? '' : e.target.value)
            props.setValue(props.name, e.target.value, { shouldDirty: true })
            if (props.setWrapperState) props.setWrapperState(e.target.value)
        }

    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        props.formRef.onBlur(e)
        setIsFocused(false)
    }

    const handleFocus = () => {
        setIsFocused(true)
    }

    return (
        <BaseField
            {...props}
            handleChange={handleChange}
            type="number"
            handleFocus={handleFocus}
            handleKeyDown={handleKeyDown}
            handleBlur={handleBlur}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            tempValue={tempValue}
            setTempValue={setTempValue}
        />
    )
}