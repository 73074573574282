import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import APIRequest from "../helpers/CreateRequest";

export default function Private(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const location = useLocation();

  const validateToken = async () => {
    try {
      const result = await new APIRequest("/auth/validate-auth-token", "GET", null, null).GenerateRequest()
      if (result.status !== 200) throw 'Failed to validate token'
      setIsAuth(true)
      setIsLoading(false)
    } catch (err) {
      setIsAuth(false);
      setIsLoading(false);
    }
  }

  useEffect(() => { validateToken(); }, []);

  if (isLoading) {
    return (
      <div className="font-bold  absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 " >
        <svg className=" animate-spin inline-block w-20 h-20 border-[3px] border-current border-t-transparent text-blue-600 rounded-full " viewBox="0 0 24 24" />
      </div>
    );
  } else {
    if (isAuth) {
      return <Outlet />;
    } else {
      return <Navigate to="/sign-in" state={{ from: location }} />;
    }
  }
}
