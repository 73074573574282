import { FormEvent, useState } from "react";
import SignInContainer from "./SignInContainer";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import APIRequest from "../../helpers/CreateRequest";
import Input from "./Input";
import Button from "./Button";

export default function ResetPassword() {
    const { Email, Code } = useLocation().state as { Email: string, Code: string } || { Email: undefined, Code: undefined }
    const [, , removeCookie] = useCookies();
    const [form, setForm] = useState({ NewPassword: "", ConfirmPassword: "" })
    const [passwordReset, setPasswordReset] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState<string | false>(false)

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)
        setIsError(false)
        try {
            if (form.NewPassword === form.ConfirmPassword) {
                // API call to reset password
                const result = await new APIRequest('/auth/password-reset-change-password', 'POST', null, { Password: form.NewPassword, ConfirmPassword: form.ConfirmPassword }, 'resetPassword').GenerateRequest()
                if (result.status !== 200) throw 'Server error'
                const body = await result.json()
                if (body.status !== 200) throw body.message
                setPasswordReset(true)
                removeCookie("resetToken", {path: "/"});

            } else throw "Passwords do not match"
        } catch (err) {
            console.log(err)
            setIsError(String(err))
        } finally {
            setIsLoading(false)
        }
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        setForm(prev => ({ ...prev, [name]: e.target.value }))
    }

    if (!Email || !Code) return <Navigate to="/sign-in" />
    else return (
        <SignInContainer>
            {
                passwordReset ?

                    <div>
                        <h1 className="font-semibold text-2xl mt-2">Password reset successful</h1>

                        <p className="w-[350px] mt-3">
                            <Link to="/sign-in" className="text-sky-500 font-semibold"  >Click here</Link> to sign in
                        </p>
                    </div>

                    :

                    <form onSubmit={handleSubmit} className="flex flex-col gap-y-2 items-center">
                        <h1 className="font-semibold text-2xl mt-2">Reset your password</h1>

                        <p className="w-[350px]">Enter your new password</p>

                        <Input
                            placeholder="New Password"
                            type="password"
                            name="NewPassword"
                            onChange={(e) => handleInput(e, "NewPassword")}
                            autoFocus
                        />
                        <Input
                            placeholder="Confirm Password"
                            type="password"
                            name="ConfirmPassword"
                            onChange={(e) => handleInput(e, "ConfirmPassword")}
                        />

                        {
                            isError && !isLoading && <span className="bg-red-100 rounded-lg w-[340px] px-5 py-2 flex flex-nowrap">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ef4444"><path d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z" /></svg>
                                <p className="text-sm m-auto">{isError}</p>
                            </span>
                        }
                        <Button text="Reset password" disabled={!form.NewPassword || !form.ConfirmPassword} isLoading={isLoading} />
                    </form>
            }
        </SignInContainer>
    )
}