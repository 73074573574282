import { LoanTermsProps } from "./JVSpecTypes";



export default function LoanTerms(props: LoanTermsProps ): JSX.Element {

    return (
        <div id="LoanTerms" className="text-pretty" >
            <header className="grid grid-cols-12 border-b-2 py-2">
                <span className="col-span-3">{props.Logo}</span>
                <span className="col-span-9 justify-self-end">{new Date().toLocaleDateString()}</span>

                <span className="col-span-12">{props.CompanyName}</span>
                <span className="col-span-12">{props.EmailAddress} | {props.PhoneNumber}</span>

                <span className="col-span-12 text-center">Project Lending Opportunity</span>

                <span className="col-span-12">{props.ProjectName}</span>
                <span className="col-span-12">{props.ProjectAddress}</span>
            </header>


            <main className="grid grid-cols-12">
                <span className="font-bold col-span-2">Purpose</span>
                <div className="grid grid-cols-12 col-span-10">
                    <span className="col-span-10">&nbsp;</span>
                    <span className="col-span-10">
                        To secure financing for a high-quality, luxury single-family spec home that meets market demand. The project aims to achieve a solid return on investment through careful planning, efficient construction, and thoughtful market positioning.
                    </span>
                </div>

                <span className="font-bold col-span-12">Project Summary</span>
                <div className="col-span-2">&nbsp;</div>
                <div className="grid grid-cols-12 col-span-10">
                    <span className="col-span-3">Total Project Cost :</span>
                    <span className="col-span-9">{props.TotalProjectCost}</span>

                    <span className="col-span-3">Estimated Sale Price :</span>
                    <span className="col-span-9">{props.EstimatedSalePrice}</span>

                    <span className="col-span-3">Estimated Gross Profit:</span>
                    <span className="col-span-9">{props.EstimatedGrossProfit}</span>

                    <span className="col-span-3">Estimated Gross Margin:</span>
                    <span className="col-span-9">{props.EstimatedGrossMargin}%</span>
                </div>

                <span className="font-bold col-span-12">Project Schedule</span>
                <div className="col-span-2">&nbsp;</div>
                <div className="grid grid-cols-12 col-span-10">
                    <span className="col-span-7">Duration to Construction Start (Pre-Con Period):</span>
                    <span className="col-span-2 text-right">{props.DurationToConstructionStart} months</span>

                    <span className="col-span-7">Duration of Construction (Construction Period):</span>
                    <span className="col-span-2 text-right">{props.DurationOfConstruction} months</span>

                    <span className="col-span-7">Duration From Completion to Settlement (Sale Period):</span>
                    <span className="col-span-2 text-right">{props.DurationFromCompletionToSettlement} months</span>

                    <span className="col-span-7">Total Duration:</span>
                    <span className="col-span-2 text-right">{props.TotalDuration} months</span>
                </div>

                <span className="font-bold col-span-12">Cost Summary</span>
                <div className="col-span-2">&nbsp;</div>
                <div className="grid grid-cols-12 col-span-10">
                    <span className="col-span-5">Land Purchase :</span>
                    <span className="col-span-7">{props.LandPurchase}</span>

                    <span className="col-span-5">Design & Planning:</span>
                    <span className="col-span-7">{props.DesignAndPlanning}</span>

                    <span className="col-span-5">Site Improvements:</span>
                    <span className="col-span-7">{props.SiteImprovements}</span>

                    <span className="col-span-5">Direct Construction:</span>
                    <span className="col-span-7">{props.DirectConstruction}</span>

                    <span className="col-span-5">Contingency Fund ({props.ContingencyFundPercent}%):</span>
                    <span className="col-span-7">{props.ContingencyFund}</span>

                    <span className="col-span-5"></span>
                    <span className="col-span-1 border-b-2 pb-2 border-black"></span>
                    <span className="col-span-3"></span>

                    <span className="col-span-5">Total Construction Costs :</span>
                    <span className="col-span-7">{props.TotalConstructionCosts}</span>

                    <span className="col-span-5">Selling, Financing & Disposition Costs :</span>
                    <span className="col-span-7">{props.SellingFinancingAndDispositionCosts}</span>

                    <span className="col-span-5"></span>
                    <span className="col-span-1 border-b-2 pb-2 border-black"></span>
                    <span className="col-span-3"></span>

                    <span className="col-span-5">Total Costs :</span>
                    <span className="col-span-7">{props.TotalCosts}</span>
                </div>

                <span className="font-bold col-span-12">Financing Assumptions</span>
                <div className="col-span-2">&nbsp;</div>
                <div className="grid grid-cols-12 col-span-10">
                    <span className="col-span-5">Estimated Loan Amount:</span>
                    <span className="col-span-7">{props.EstimatedLoanAmount}</span>

                    <span className="col-span-5">Loan-to-Cost (LTC) Ratio:</span>
                    <span className="col-span-7">{props.LoanToCostRatio} %</span>

                    <span className="col-span-5">Interest Rate :</span>
                    <span className="col-span-7">{props.InterestRate}%</span>

                    <span className="col-span-5">Loan Term :</span>
                    <span className="col-span-7">{props.LoanTerm} Months</span>

                    <span className="col-span-5">Estimated Financing & Transaction Costs :</span>
                    <span className="col-span-7">{props.EstimatedFinancingAndTransactionCosts}</span>

                    <span className="col-span-5">Borrowing Interest:</span>
                    <span className="col-span-7">{props.BorrowingInterest}</span>
                </div>

                <span className="col-span-12 mt-4">{props.SignatoryName}</span>
                <span className="col-span-12">{props.SignatoryTitle}</span>
                <span className="col-span-12">{props.CompanyName}</span>
                <span className="col-span-12">{props.PhoneNumber}</span>
                <span className="col-span-12">{props.EmailAddress}</span>

            </main>

            <footer>

            </footer>

        </div>
    )
}