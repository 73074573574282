import APIRequest from "../../helpers/CreateRequest";
import { useEffect, useMemo, useState } from "react";
import CRUD from "../../templates/CRUD";
import { FieldValues } from "react-hook-form";
import GetObj from "../../helpers/GetObj";
import { field, mode } from "../../Types/FormTypes";
import { useLocation } from "react-router-dom";

const name = {
  normal: 'Position',
  plural: 'Positions',
  alias: 'Position',
  aliasPlural: 'Positions',
  data: 'Position',
  dataPlural: 'Positions',
  sectionTitle: 'Operations Management'
}

const fields: field[] = [
  { name: 'PositionName', alias: 'Name', sendAs: 'Name', type: 'text', required: true, size: 'lg' },
  { name: 'Description', type: 'text', size: 'xl', },
  { name: 'Code', type: 'text', required: true, size: 'md' },
  { name: "DepartmentID", type: 'select', size: 'md', SelectElement: { name: 'Department', namePlural: 'Departments' }, alias: 'Department', required: true }
]
export default function Positions() {
  const location = useLocation()
  const stateID = location.state?.id || undefined
  const [obj, setObj] = useState<any>()
  const [objID, setObjID] = useState(stateID)
  const [mode, setMode] = useState<mode>('view')
  const [refresh, setRefresh] = useState(false)

  const emptySet: FieldValues = useMemo(() => Object.fromEntries(fields.map(field => [field.sendAs || field.name, null])), [])

  //Get object when the id changes or a refresh is called 
  useEffect(() => {
    const getObj = async () => {
      if (objID) {
        const obj = await GetObj(objID, name.dataPlural, fields)
        if (obj !== -1) {
          setObj(obj)
          setMode('view')
        }
      }
    }
    getObj()
  }, [objID, refresh])

  return (
    <>
      <div className="py-10 px-10 h-full overflow-y-auto">

        <CRUD
          name={name}
          fields={fields}
          obj={obj}
          id={objID}
          setID={setObjID}
          mode={mode}
          setMode={setMode}
          emptySet={emptySet}
          refresh={refresh}
          setRefresh={setRefresh}
          extraButtons={[]}
        />


        {/* Details or sections */}
        <div className="flex-grow w-full m-auto">
          {objID && <RolesByPosition positionID={objID} />}
        </div>
      </div>
    </>

  )
}



function RolesByPosition({ positionID }: { positionID: number }) {
  const [roles, setRoles] = useState<{ RoleID?: number, RoleName?: string, Description?: string }[]>([])

  useEffect(() => {
    const GetRoles = async () => {
      const result = await new APIRequest('/roles/byPosition', 'POST', null, { id: positionID }).GenerateRequest()
      const body: any = await result.json()
      setRoles(body.recordset)
    }
    GetRoles()
  }, [positionID])

  return (
    <>
      {
        roles &&

        <div className="flex flex-col gap-y-5  h-full px-5 py-5">

          <div className="font-bold text-lg">Assigned Roles</div>


          <div className="grid grid-cols-2 border-b-2 pb-2">
            <span className="font-bold text-lg">Name</span>
            <span className="font-bold text-lg">Description</span>
          </div>


          {
            roles.length > 0 ?
              roles.map(role => (
                <div key={`${role.RoleID}RoleByPosition`} className="grid grid-cols-2 w-full first:border-t border-b-2 pb-3">
                  <span>{role.RoleName}</span>
                  <span>{role.Description}</span>
                </div>
              ))

              :

              <span className="flex m-auto text-lg">No roles found</span>

          }

        </div>

      }



    </>


  )
}



