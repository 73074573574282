import APIRequest from "../helpers/CreateRequest";
import { Link, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import SnackBar from "../components/molecules/SnackBar";
import { useEffect, useState } from "react";
import Button from "../components/atoms/Button";
import BEPLogo from "../assets/BEP-Logo.png";
import path from "path";


export default function HeaderTop({ helpOffcanvasOpen, setHelpOffcanvasOpen, isJVSpecUser, setIsJVSpecUser }: { helpOffcanvasOpen: boolean, setHelpOffcanvasOpen: React.Dispatch<React.SetStateAction<boolean>>, isJVSpecUser: boolean, setIsJVSpecUser: React.Dispatch<React.SetStateAction<boolean>> }): JSX.Element {
  const navigate = useNavigate();
  const [cookies, , removeCookie] = useCookies();
  const [message, setMessage] = useState({ message: '', refresh: false })
  const [isUserOpen, setIsUserOpen] = useState(false);
  const tenantName = new Cookies().get("settings")?.TenantName || ''


  async function signOut() {
    try {
      const result = await new APIRequest("/auth/sign-out", "GET", null, null).GenerateRequest();

      if (result.status === 200) {
          removeCookie("token", {path: "/"});
          removeCookie("settings", {path: "/"});
          removeCookie("userType", {path: "/"});
          removeCookie("signUpToken", {path: "/"});
          removeCookie("resetToken", {path: "/"});
          navigate("/sign-in");

      } else throw 'Server error. Unable to sign out'
    } catch (err) {
      setMessage({ message: 'Server error. Unable to sign out', refresh: !message.refresh })
    } finally {
        navigate("/sign-in");
    }
  }

  // set up event handler for when a click is made and set the state of the user open to false if not inside the isUserOpen div
  useEffect(() => {
    function handleClickOutside(event: any) {
      const userDiv = document.getElementById("userDiv");
      // set to false if it cant find userDiv or if it does not contain the event target, or if it is the event target
      if (!userDiv || (userDiv && (!userDiv.contains(event.target)))) {
        setIsUserOpen(false);
      } else {
        setIsUserOpen(true);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [])

  return (
    <>
      <div className={`bg-md-background w-full ${isJVSpecUser ? "h-[80]" : 'h-[40px]'} flex justify-center align-middle text-left px-4 bg-white border-b`}>
        {
          isJVSpecUser ?

            <>
              <span className="flex items-center gap-x-2 cursor-pointer">
                <img src={BEPLogo} alt="BuildExecPro Logo" className="w-8 h-8" />
                <span className="font-bold text-2xl text-sky-500 pointer-events-none">BuildExecPro</span>
              </span>

              <span id="userDiv" className="h-[48px] relative flex flex-row gap-x-4 m-auto mr-0 items-center" >
                <svg className="h-full cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q53 0 100-15.5t86-44.5q-39-29-86-44.5T480-280q-53 0-100 15.5T294-220q39 29 86 44.5T480-160Zm0-360q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0-60Zm0 360Z" /></svg>
                {
                  isUserOpen && (
                    <div className="absolute rounded-sm px-1 w-[240px] py-4 h-max -bottom-[240px] right-[0px] shadow-2xl flex flex-col gap-y-3 z-50 bg-sky-50 text-gray-700 fill-[#334155]">
                      <div className="flex flex-col gap-y-1 px-5">
                        <h3 className="text-base">John Smith</h3>
                        <h5 className="text-xs">john.smith@buildsoftsolutions.com</h5>
                        <h3 className="text-xs">Standard Plan</h3>
                      </div>

                      <hr className="w-full border-gray-700" />

                      <span className="px-5 hover:text-sky-500 transition-colors duration-200 flex gap-x-3 items-center cursor-pointer hover:fill-[#0ea5e9] font-semibold">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" ><path d="m387.69-100-15.23-121.85q-16.07-5.38-32.96-15.07-16.88-9.7-30.19-20.77L196.46-210l-92.3-160 97.61-73.77q-1.38-8.92-1.96-17.92-.58-9-.58-17.93 0-8.53.58-17.34t1.96-19.27L104.16-590l92.3-159.23 112.46 47.31q14.47-11.46 30.89-20.96t32.27-15.27L387.69-860h184.62l15.23 122.23q18 6.54 32.57 15.27 14.58 8.73 29.43 20.58l114-47.31L855.84-590l-99.15 74.92q2.15 9.69 2.35 18.12.19 8.42.19 16.96 0 8.15-.39 16.58-.38 8.42-2.76 19.27L854.46-370l-92.31 160-112.61-48.08q-14.85 11.85-30.31 20.96-15.46 9.12-31.69 14.89L572.31-100H387.69ZM440-160h78.62L533-267.15q30.62-8 55.96-22.73 25.35-14.74 48.89-37.89L737.23-286l39.39-68-86.77-65.38q5-15.54 6.8-30.47 1.81-14.92 1.81-30.15 0-15.62-1.81-30.15-1.8-14.54-6.8-29.7L777.38-606 738-674l-100.54 42.38q-20.08-21.46-48.11-37.92-28.04-16.46-56.73-23.31L520-800h-79.38l-13.24 106.77q-30.61 7.23-56.53 22.15-25.93 14.93-49.47 38.46L222-674l-39.38 68L269-541.62q-5 14.24-7 29.62t-2 32.38q0 15.62 2 30.62 2 15 6.62 29.62l-86 65.38L222-286l99-42q22.77 23.38 48.69 38.31 25.93 14.92 57.31 22.92L440-160Zm40.46-200q49.92 0 84.96-35.04 35.04-35.04 35.04-84.96 0-49.92-35.04-84.96Q530.38-600 480.46-600q-50.54 0-85.27 35.04T360.46-480q0 49.92 34.73 84.96Q429.92-360 480.46-360ZM480-480Z" /></svg>
                        <Link to="#">Settings</Link>
                      </span>

                      <hr className="w-full border-gray-700" />

                      <span className="px-5 hover:text-sky-500 transition-colors duration-200 flex gap-x-3 items-center cursor-pointer hover:fill-[#0ea5e9] font-semibold">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M479.56-255.39q17.13 0 28.94-11.82 11.81-11.83 11.81-28.97 0-17.13-11.83-28.94-11.83-11.8-28.96-11.8-17.13 0-28.94 11.83-11.81 11.83-11.81 28.96 0 17.13 11.83 28.94 11.83 11.8 28.96 11.8Zm-28.33-143.23h56.31q.77-29.53 8.65-47.19 7.89-17.65 38.27-46.8 26.39-26.39 40.42-48.74 14.04-22.34 14.04-52.77 0-51.65-37.11-80.69-37.12-29.03-87.81-29.03-50.08 0-82.88 26.73-32.81 26.73-46.81 62.96l51.38 20.61q7.31-19.92 25-38.81 17.69-18.88 52.54-18.88 35.46 0 52.42 19.42 16.97 19.43 16.97 42.73 0 20.39-11.62 37.31-11.61 16.92-29.61 32.69-39.39 35.54-49.77 56.7-10.39 21.15-10.39 63.76ZM480.07-100q-78.84 0-148.21-29.92t-120.68-81.21q-51.31-51.29-81.25-120.63Q100-401.1 100-479.93q0-78.84 29.92-148.21t81.21-120.68q51.29-51.31 120.63-81.25Q401.1-860 479.93-860q78.84 0 148.21 29.92t120.68 81.21q51.31 51.29 81.25 120.63Q860-558.9 860-480.07q0 78.84-29.92 148.21t-81.21 120.68q-51.29 51.31-120.63 81.25Q558.9-100 480.07-100Zm-.07-60q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>
                        <Link to="#" onClick={() => setHelpOffcanvasOpen(!helpOffcanvasOpen)}>Help</Link>
                      </span>

                      <hr className="w-full border-gray-700" />

                      <span className="px-5 hover:text-sky-500 transition-colors duration-200 flex gap-x-3 items-center cursor-pointer hover:fill-[#0ea5e9] font-semibold">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M212.31-140Q182-140 161-161q-21-21-21-51.31v-535.38Q140-778 161-799q21-21 51.31-21h268.07v60H212.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v535.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85h268.07v60H212.31Zm436.92-169.23-41.54-43.39L705.08-450H363.85v-60h341.23l-97.39-97.38 41.54-43.39L820-480 649.23-309.23Z" /></svg>
                        <Link to="#" onClick={signOut}>Sign out</Link>
                      </span>
                    </div>
                  )
                }
              </span>
            </>

            :

            <>
              <span className="m-auto ml-0 title-small">
                {tenantName}: {process.env.REACT_APP_ENV}
              </span>

              <span className="flex flex-row gap-x-4 m-auto mr-0 items-center justify-center h-full">
                <Button
                  label="Help"
                  styleType="text"
                  type="button"
                  action={() => setHelpOffcanvasOpen(!helpOffcanvasOpen)}
                />

                <Button
                  label="Sign out"
                  styleType="text"
                  type="button"
                  action={() => signOut()}
                />
              </span>
            </>
        }
      </div>

      <SnackBar message={message} />
    </>

  );
}
