

export default function Button({ text, disabled, isLoading }: { text: string, disabled?: boolean, isLoading?: boolean }) {
    return (

        <button
            className={`w-[340px] h-12 p-2 pl-4 text-white  bg-sky-500 rounded-md outline-none hover:bg-sky-600 focus:bg-sky-500 transition-colors disabled:bg-gray-300 flex font-semibold items-center text-center justify-center`}
            type="submit"
            disabled={disabled || isLoading}
        >{isLoading ? <div className="p-2 h-[40px] w-[40px] flex">
            <svg className="animate-spin inline-block m-auto w-full h-full border-[3px] border-current border-t-transparent text-md-inverse-primary rounded-full" viewBox="0 0 16 16" />
        </div> : text}</button>
    )
}