import { Link } from "react-router-dom";
import BuildSoftSolutionsLogo from "../../assets/BSS-Logo.jpg";
import BEPLogo from "../../assets/BEP-Logo.png";


export default function SignInContainer({ children }: { children: JSX.Element }): JSX.Element {

    return (
        <div className="h-full w-screen bg-gradient-to-br  from-sky-500 via-sky-200 to-sky-50 text-center absolute text-gray-700">
            <div className="absolute flex flex-col gap-y-2  items-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-max py-8 w-[400px] bg-white rounded-3xl shadow-lg">
                <Link to='/' className="font-bold text-2xl text-sky-500 cursor-pointer flex items-center gap-x-1">
                    <img src={BEPLogo} alt="logo" className="w-8 h-8" />
                    <span>BuildExecPro</span>
                </Link>

                {children}
            </div>
            {/* Footer */}
            <footer className="w-full bg-white py-3 text-sm absolute bottom-0">
                <div className="w-full max-w-[1200px] flex gap-x-7 justify-between px-3 m-auto">
                    <Link className="w-[120px]" to="https://buildsoftsolutions.com/"><img src={BuildSoftSolutionsLogo} alt="BuildSoftSolutions Logo" className="w-30 h-10 my-auto" /></Link>
                    <span className="w-max flex gap-x-2 items-center">
                        <p className="my-auto">© 2024 BuildExecPro. All rights reserved.</p>
                        <span>•</span>
                        <Link className="my-auto" to='https://buildsoftsolutions.com/privacy-policy/'>Privacy Policy</Link>
                    </span>
                    <Link className="my-auto w-[120px] text-center" to='/sign-up'>Partner Referral</Link>
                </div>
            </footer>
        </div>
    )
}