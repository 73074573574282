import { Link, useLocation } from "react-router-dom";
import HomePageHomeBuilder from "../../assets/BEP Home Page Home Builder.png";
import BEPLogo from "../../assets/BEP-Logo.png";
import BuildSoftSolutionsLogo from "../../assets/BSS-Logo.jpg";
import { FormEvent, useEffect, useState } from "react";
import TestimonialCarousel from "./Testimonials";
import APIRequest from "../../helpers/CreateRequest";

export default function ProductMain(): JSX.Element {
  const scrollTo = useLocation().hash
  const [questionForm, setQuestionForm] = useState({ FirstName: '', LastName: '', Email: '', Message: '' });
  const [contactForm, setContactForm] = useState({ FirstName: '', LastName: '', Email: '' });
  const [contactFormActive, setContactFormActive] = useState(false);
  const [contactFormSuccess, setContactFormSuccess] = useState(false);
  const [questionFormSuccess, setQuestionFormSuccess] = useState(false);

  const handleContactFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try{
      const result = new APIRequest('/product-main/contact', 'POST', null, contactForm).GenerateRequest()
  
      setContactFormSuccess(true);
    }catch (err){
      console.log(err)
    }
  }

  const handleQuestionsFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try{
      const result = new APIRequest('/product-main/contact', 'POST', null, questionForm).GenerateRequest()
  
      setQuestionFormSuccess(true);
    }catch (err){
      console.log(err)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const scrollToWhyBuildExecPro = () => {
    const whyBuildExecProSection = document.getElementById('WhyBuildExecProSection');
    if (whyBuildExecProSection) {
      whyBuildExecProSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  const scrollToPricing = () => {
    const pricingSection = document.getElementById('PricingSection');
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  const scrollToSignUp = () => {
    const signUpForm = document.getElementById('signUpForm');
    if (signUpForm) {
      signUpForm.scrollIntoView({ behavior: 'smooth' });
      const nameInput = document.getElementById('QuestionFirstNameInput');
      if (nameInput) {
        nameInput.focus({ preventScroll: true });
      }
    }
  }

  useEffect(() => {
    if (scrollTo === '#pricing') scrollToPricing();
  }, [scrollTo])


  return (
    <div className="w-full h-full flex flex-col items-center text-lg text-gray-700 relative">
      {/* Nav Bar */}
      <nav className="sticky top-0 z-40 w-full  bg-white">
        <div className="max-w-[2400px] flex justify-between items-center py-5 px-10">

          <div className="flex items-center gap-x-2 cursor-pointer" onClick={scrollToTop}>
            <img src={BEPLogo} alt="BuildExecPro Logo" className="w-8 h-8" />
            <span className="font-bold text-2xl text-sky-500 pointer-events-none" >BuildExecPro</span>
          </div>

          <div className="flex items-center space-x-6 text-gray-700">
            <Link to="#" className="hover:text-sky-500" onClick={scrollToWhyBuildExecPro}>Why BuildExecPro</Link>
            <Link to="#" className="hover:text-sky-500" onClick={scrollToPricing}>Pricing</Link>
            <Link to="#" className="hover:text-sky-500" onClick={scrollToSignUp}>Contact</Link>
          </div>

          <div className="flex items-center space-x-4 text-gray-700">
            <Link to="/sign-in" className="hover:text-sky-500">Sign in</Link>
            <Link to="#" className="bg-sky-500 text-white font-bold px-4 py-2 rounded hover:bg-sky-600" onClick={scrollToPricing}>Get Started</Link>
          </div>
        </div>

      </nav>

      {/* Hero section */}
      <section className="relative w-full h-[500px] flex flex-nowrap items-center justify-center bg-gradient-to-br  from-sky-500 via-sky-200 to-sky-50 gap-x-10">
        <Triangle bottom={87} right={10} rotate={12} />
        <Triangle bottom={20} right={10} rotate={-33} />
        <Triangle bottom={60} right={20} rotate={45} />
        <Triangle bottom={10} right={30} rotate={60} />
        <Triangle bottom={80} right={40} rotate={12} />
        <Triangle bottom={50} right={50} rotate={90} />
        <Triangle bottom={5} right={60} rotate={120} />
        <Triangle bottom={50} right={70} rotate={-12} />
        <Triangle bottom={80} right={80} rotate={-30} />
        <Triangle bottom={10} right={90} rotate={-90} />
        <Triangle bottom={60} right={90} />

        <div className="relative z-20 text-center ">
          <h1 className="text-5xl text-gray-800 font-bold mb-4 max-w-[500px] ">Spec deals made easy.</h1>


          <p className="text-2xl text-gray-600 mb-6 max-w-[500px] text-left">
            Streamline deal creation, optimize financial projections, and increase transparency.</p>
          <div className="flex gap-x-6">
            <button className="bg-sky-500 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-sky-600" onClick={scrollToPricing}>Get Started</button>
            <button className="bg-transparent hover:text-sky-600 underline underline-offset-4" onClick={scrollToWhyBuildExecPro}>Learn More</button>
          </div>


        </div>
        <img src={HomePageHomeBuilder} alt="Product Showcase" className="h-3/4 w-auto  rounded-3xl hidden lg:block opacity-70 z-20" />
      </section>

      {/* partners/testimonials */}
      <div className="bg-sky-50  w-full py-10">
        <TestimonialCarousel />
      </div>

      {/* Features Section */}
      <div id="WhyBuildExecProSection" className="relative w-full from-violet-50 via-violet-300 via-70% to-violet-400  bg-gradient-to-br ">
        <Triangle bottom={80} right={70} color="#a78bfa" rotate={12} />
        <Triangle bottom={20} right={10} color="#a78bfa" rotate={-45} />
        <Triangle bottom={60} right={20} color="#a78bfa" />
        <Triangle bottom={10} right={30} color="#a78bfa" rotate={12} />
        <Triangle bottom={20} right={40} color="#a78bfa" rotate={-90} />
        <Triangle bottom={50} right={50} color="#a78bfa" rotate={150} />
        <Triangle bottom={10} right={60} color="#a78bfa" rotate={90} />
        <Triangle bottom={50} right={70} color="#a78bfa" rotate={-12} />
        <Triangle bottom={80} right={30} color="#a78bfa" rotate={-30} />
        <Triangle bottom={10} right={80} color="#a78bfa" rotate={-90} />
        <Triangle bottom={20} right={70} color="#a78bfa" rotate={-12} />
        <div className="relative max-w-[1200px] m-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-12 p-10 z-20">
          <div className="col-span-1 md:col-span-1 lg:col-span-2 flex justify-center items-center">
            <img src="https://via.placeholder.com/600x400" alt="Product Showcase" className="rounded-xl shadow-lg" />
          </div>

          <div className="col-span-1 md:col-span-1 lg:col-span-2 p-4">
            <h3 className="text-4xl font-bold mb-4 text-gray-800">Why BuildExecPro</h3>
            <p className="text-gray-700 leading-relaxed">
              Securing capital is one of the toughest challenges land developers and home builders face. BuildExecPro equips you with the tools to streamline project planning, optimize financial projections, and present your projects in ways that attract investors and lenders.
              With real-time insights and intuitive features, you'll secure funding faster and maximize profitability—saving time and boosting your bottom line.
            </p>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className=" w-full bg-sky-50 ">
        <div className="max-w-[1200px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 p-10 m-auto">
          <div className="col-span-1 md:col-span-1 lg:col-span-2">
            <h2 className="text-4xl font-bold mb-4">Unlock the Power of Your Data</h2>
            <ul className="list-disc text-xl px-10 ">
              <li><strong>Real-Time Analytics</strong>
                <ul>
                  <li>Gain insights as they happen with our powerful analytics tools.</li>
                </ul>
              </li>
              <li className="mt-2"><strong>AI Insights</strong>
                <ul>
                  <li>Leverage AI to make data-driven decisions quickly.</li>
                </ul>
              </li>
              <li className="mt-2"><strong>Data Optimization</strong>
                <ul>
                  <li>Maximize efficiency with advanced data management features.</li>
                </ul>
              </li>
              <li className="mt-2"><strong>Instant Reporting</strong>
                <ul>
                  <li>Generate reports in seconds with our intuitive interface.</li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-span-1 md:col-span-1 lg:col-span-2 flex justify-center items-center">
            <img src="https://via.placeholder.com/600x400" alt="Product Showcase" className="rounded-xl shadow-lg" />
          </div>
        </div>
      </div>

      {/* Pricing */}
      <section id="PricingSection" className="relative w-full bg-gradient-to-br from-violet-400 to-violet-50 py-10 flex flex-col gap-y-4 items-center" >
        <Triangle bottom={80} right={90} color="#a78bfa" rotate={12} />
        <Triangle bottom={20} right={10} color="#a78bfa" rotate={45} />
        <Triangle bottom={60} right={20} color="#a78bfa" />
        <Triangle bottom={10} right={30} color="#a78bfa" rotate={12} />
        <Triangle bottom={80} right={40} color="#a78bfa" rotate={90} />
        <Triangle bottom={50} right={50} color="#a78bfa" />
        <Triangle bottom={5} right={60} color="#a78bfa" rotate={90} />
        <Triangle bottom={50} right={70} color="#a78bfa" rotate={-12} />
        <Triangle bottom={80} right={30} color="#a78bfa" rotate={-30} />
        <Triangle bottom={10} right={80} color="#a78bfa" rotate={-90} />
        <Triangle bottom={60} right={90} color="#a78bfa" rotate={-30} />


        <div className="w-full flex flex-col gap-y-8 max-w-[800px] z-20">
          <div className="m-auto">
            <h2 className="text-4xl font-bold text-gray-800">One Plan, Everything You Need</h2>
          </div>

          <div className={`flex items-center w-full relative`}>
            {/* pricing card */}
            <div className={`bg-gray-100 shadow-lg rounded-lg w-[300px] m-auto py-4 px-3 gap-y-5 flex flex-col transition-all duration-500  z-10 ${contactFormActive ? '-translate-x-3/4' : 'translate-x-0'}`}>
              {/* Card Title */}
              <h2 className="text-3xl font-bold text-gray-800 text-center">Standard Plan</h2>

              <div className="text-center">
                <Link to="#" className="bg-sky-500 text-white font-bold px-4 py-2 rounded hover:bg-sky-600" onClick={() => setContactFormActive(!contactFormActive)}>Get Quote</Link>
              </div>

              {/* Description */}
              <p className="text-gray-600 text-center ">
                Unlock all features with our Standard Plan. Includes real-time analytics, AI insights, and instant reporting tools.
              </p>

              {/* Feature List */}
              <ul className="space-y-3  mb-6 text-gray-700 m-auto ">
                <li className="flex items-center">
                  <span className="text-green-500 text-xl mr-2">✓</span> Real-Time Analytics
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 text-xl mr-2">✓</span> AI-Powered Insights
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 text-xl mr-2">✓</span> Instant Reports
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 text-xl mr-2">✓</span> Priority Support
                </li>
              </ul>

            </div>

            {/* Form to contact for pricing */}
            <form id="pricingContactForm" className={`w-min py-4 m-auto flex flex-col gap-y-4 items-center transition-all duration-500 absolute top-0 right-10  ${contactFormActive ? 'translate-x-0 opacity-100' : '-translate-x-1/3 opacity-0 pointer-events-none'}`} onSubmit={handleContactFormSubmit} >
              {
                contactFormSuccess ?

                  <div className="m-auto w-[400px]">
                    <h2 className="text-center text-4xl font-semibold text-gray-900">Thank you!</h2>
                    <p className="text-center my-2">We will be in touch soon.</p>
                  </div>

                  :

                  <>
                    <h2 className="text-4xl font-bold text-gray-900">Contact us for pricing</h2>
                    <p>
                      Are you an early adopter interested in collaborating on the development of a solution that makes your deals more streamlined, accurate and profitable?
                    </p>
                    <div className="flex flex-col gap-y-4">
                      <input
                        id="firstNameInput"
                        type="text"
                        placeholder="First name"
                        className="bg-white text-gray-700 px-4 py-2 rounded-lg w-[300px] md:w-[400px] shadow-md focus:outline-none focus:ring-2 focus:ring-sky-500 transition-all duration-200 ease-in-out"
                        value={contactForm.FirstName}
                        onChange={(e) => setContactForm({ ...contactForm, FirstName: e.target.value })}
                        required
                        tabIndex={contactFormActive ? -1 : 1}
                      />
                      <input
                        id="lastNameInput"
                        type="text"
                        placeholder="Last name"
                        className="bg-white text-gray-700 px-4 py-2 rounded-lg w-[300px] md:w-[400px] shadow-md focus:outline-none focus:ring-2 focus:ring-sky-500 transition-all duration-200 ease-in-out"
                        value={contactForm.LastName}
                        onChange={(e) => setContactForm({ ...contactForm, LastName: e.target.value })}
                        required
                        tabIndex={contactFormActive ? -1 : 1}
                      />
                      <input
                        id="emailInput"
                        type="email"
                        placeholder="Email"
                        className="bg-white text-gray-700 px-4 py-2 rounded-lg w-[300px] md:w-[400px] shadow-md focus:outline-none focus:ring-2 focus:ring-sky-500 transition-all duration-200 ease-in-out"
                        value={contactForm.Email}
                        onChange={(e) => setContactForm({ ...contactForm, Email: e.target.value })}
                        required
                        tabIndex={contactFormActive ? -1 : 1}
                      />

                      <button
                        type="submit"
                        className="bg-sky-500 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-sky-600 transition-all duration-200 ease-in-out"
                        tabIndex={contactFormActive ? -1 : 1}
                      >
                        Submit
                      </button>
                    </div>
                  </>
              }

            </form>

          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="relative w-full bg-sky-50">
        {/* <Triangle bottom={80} right={10} rotate={12} />
        <Triangle bottom={20} right={10} />
        <Triangle bottom={60} right={20} rotate={45} />
        <Triangle bottom={10} right={30} />
        <Triangle bottom={80} right={40} rotate={12} />
        <Triangle bottom={50} right={50} rotate={90} />
        <Triangle bottom={5} right={60} />
        <Triangle bottom={50} right={70} rotate={-12} />
        <Triangle bottom={80} right={80} rotate={-30} />
        <Triangle bottom={10} right={90} rotate={-90} />
        <Triangle bottom={60} right={90} /> */}



        <form id="signUpForm" className="relative py-10 flex flex-col gap-y-4 items-center z-20" onSubmit={handleQuestionsFormSubmit}>
          {
            questionFormSuccess ?

              <div className="m-auto w-[400px] h-[200px]">
                <h2 className="text-center text-4xl font-semibold text-gray-900">Thank you!</h2>
                <p className="text-center my-2">We will be in touch soon.</p>
              </div>

              :
              <>
                <h2 className="text-4xl font-bold mb-4 text-gray-900">Have Questions? Let's Talk.</h2>
                <p className=" mb-6 text-center max-w-[600px]">Contact our team today and see how BuildExecPro can help you streamline your projects and secure funding faster.</p>
                <div className="flex flex-col gap-y-4">
                  <input
                    id="QuestionFirstNameInput"
                    type="text"
                    placeholder="First name"
                    className="bg-white text-gray-700 px-4 py-2 rounded-lg w-[300px] md:w-[400px] shadow-md focus:outline-none focus:ring-2 focus:ring-sky-500 transition-all duration-200 ease-in-out"
                    value={questionForm.FirstName}
                    onChange={(e) => setQuestionForm({ ...questionForm, FirstName: e.target.value })}
                    required
                  />
                  <input
                    id="QuestionLastNameInput"
                    type="text"
                    placeholder="Last name"
                    className="bg-white text-gray-700 px-4 py-2 rounded-lg w-[300px] md:w-[400px] shadow-md focus:outline-none focus:ring-2 focus:ring-sky-500 transition-all duration-200 ease-in-out"
                    value={questionForm.LastName}
                    onChange={(e) => setQuestionForm({ ...questionForm, LastName: e.target.value })}
                    required
                  />
                  <input
                    id="QuestionEmailInput"
                    type="email"
                    placeholder="Email"
                    className="bg-white text-gray-700 px-4 py-2 rounded-lg w-[300px] md:w-[400px] shadow-md focus:outline-none focus:ring-2 focus:ring-sky-500 transition-all duration-200 ease-in-out"
                    value={questionForm.Email}
                    onChange={(e) => setQuestionForm({ ...questionForm, Email: e.target.value })}
                    required
                  />
                  <textarea
                    id="QuestionMessageInput"
                    onChange={(e) => setQuestionForm({ ...questionForm, Message: e.target.value })}
                    value={questionForm.Message}
                    placeholder="Message"
                    className="bg-white text-gray-700 px-4 py-2 rounded-lg w-[300px] md:w-[400px] shadow-md focus:outline-none focus:ring-2 focus:ring-sky-500 transition-all duration-200 ease-in-out"
                    tabIndex={contactFormActive ? -1 : 0}
                  />
                  <button
                    type="submit"
                    className="bg-sky-500 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-sky-600 transition-all duration-200 ease-in-out">
                    Submit
                  </button>
                </div>
              </>
          }
        </form>


      </section>


      {/* Footer */}
      <footer className="w-full bg-white py-3 text-sm">
        <div className="w-full max-w-[1200px] flex gap-x-7 justify-between px-3 m-auto">
          <Link className="w-[120px]" to="https://buildsoftsolutions.com/"><img src={BuildSoftSolutionsLogo} alt="BuildSoftSolutions Logo" className="w-30 h-10 my-auto" /></Link>
          <span className="w-max flex gap-x-2 items-center">
            <p className="my-auto">© 2024 BuildExecPro. All rights reserved.</p>
            <span>•</span>
            <Link className="my-auto" to='https://buildsoftsolutions.com/privacy-policy/'>Privacy Policy</Link>
          </span>
          <Link className="my-auto w-[120px] text-center" to='/sign-up'>Partner Referral</Link>
        </div>
      </footer>
    </div>
  );
}


function Triangle({ bottom, right, color, rotate }: { bottom: number, right: number, color?: string, rotate?: number }) {
  const style: React.CSSProperties = {
    bottom: `${bottom}%`,
    right: `${right}%`,
    borderBottomColor: color ?? '#38bdf8',
    transform: rotate ? `rotate(${rotate}deg)` : 'rotate(180deg)'
  }

  return (
    <div className={"absolute w-0 h-0 border-l-[50px] z-10 border-l-transparent border-b-[50px] border-r-[50px] border-r-transparent opacity-20  "}
      style={style}
    ></div>
  )
}