import { Cookies } from "react-cookie"

export default class APIRequest{
    private url: string
    private method: string
    private headers: HeadersInit
    private body: any
    private type: string

    constructor(url: string, method: string, headers: Headers | null, bodyData: any, type?: string){
        const baseURL = process.env.REACT_APP_API_URL || ''
        this.url = baseURL + url
        this.method = method;
        this.type = type || "auth"
        if(headers){
            this.headers = headers
        }else if (this.type === "resetPassword"){
            this.headers = {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow": "true",
                Authorization: `Bearer ${new Cookies().get('resetToken')}`,
            }
        }else if (this.type === "signUp"){
            this.headers = {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow": "true",
                Authorization: `Bearer ${new Cookies().get('signUpToken')}`,
            }
        }else {
            this.headers = {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow": "true",
                Authorization: `Bearer ${new Cookies().get('token')}`,
            }
        }
 
        this.body = method !== "GET" ? JSON.stringify(bodyData) : undefined
    }

    public async GenerateRequest(): Promise<Response>{
        return await fetch(this.url, {
            method: this.method,
            headers: this.headers,
            body: this.body
        })
    }
}