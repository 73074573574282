import jsPDF from "jspdf";
import { InvestorTermsProps, LoanTermsProps, type JVSpec, type JVSpecCalculations } from "./JVSpecTypes";
import { useEffect, useState } from "react";
import LoanTerms from "./LoanTerms";
import InvestorTerms from "./InvestorTerms";
import Modal from "../../components/atoms/Modal";
import BEPLogo from "../../assets/BEP-Logo.png";
import { Cookies } from "react-cookie";


const reports = [
    {
        name: "Investor",
        svg: <svg xmlns="http://www.w3.org/2000/svg" height="64px" viewBox="0 -960 960 960" width="64px" fill="#000000"><path d="M120-120v-80l80-80v160h-80Zm160 0v-240l80-80v320h-80Zm160 0v-320l80 81v239h-80Zm160 0v-239l80-80v319h-80Zm160 0v-400l80-80v480h-80ZM120-327v-113l280-280 160 160 280-280v113L560-447 400-607 120-327Z" /></svg>
    },
    {
        name: "Loan",
        svg: <svg xmlns="http://www.w3.org/2000/svg" height="64px" viewBox="0 -960 960 960" width="64px" fill="#000000"><path d="M160-391h45l23-66h104l24 66h44l-97-258h-46l-97 258Zm81-103 38-107h2l38 107h-78Zm319-70v-68q33-14 67.5-21t72.5-7q26 0 51 4t49 10v64q-24-9-48.5-13.5T700-600q-38 0-73 9.5T560-564Zm0 220v-68q33-14 67.5-21t72.5-7q26 0 51 4t49 10v64q-24-9-48.5-13.5T700-380q-38 0-73 9t-67 27Zm0-110v-68q33-14 67.5-21t72.5-7q26 0 51 4t49 10v64q-24-9-48.5-13.5T700-490q-38 0-73 9.5T560-454ZM260-320q47 0 91.5 10.5T440-278v-394q-41-24-87-36t-93-12q-36 0-71.5 7T120-692v396q35-12 69.5-18t70.5-6Zm260 42q44-21 88.5-31.5T700-320q36 0 70.5 6t69.5 18v-396q-33-14-68.5-21t-71.5-7q-47 0-93 12t-87 36v394Zm-40 118q-48-38-104-59t-116-21q-42 0-82.5 11T100-198q-21 11-40.5-1T40-234v-482q0-11 5.5-21T62-752q46-24 96-36t102-12q58 0 113.5 15T480-740q51-30 106.5-45T700-800q52 0 102 12t96 36q11 5 16.5 15t5.5 21v482q0 23-19.5 35t-40.5 1q-37-20-77.5-31T700-240q-60 0-116 21t-104 59ZM280-499Z" /></svg>
    }

]

export default function ReportModal({ isReportSelectOpen, setIsReportSelectOpen, form, calculations }: { isReportSelectOpen: boolean, setIsReportSelectOpen: React.Dispatch<React.SetStateAction<boolean>>, form: JVSpec, calculations: JVSpecCalculations }) {
    const [loanTermsProps, setLoanTermsProps] = useState<LoanTermsProps>()
    const [investorTermsProps, setInvestorTermsProps] = useState<InvestorTermsProps>()


    const downloadFile = async (report: string) => {
        try {
            // Get the content to download
            const downloadContent = document.getElementById(report) as HTMLElement;

            if (!downloadContent) return;

            // Create and save the PDF once content is fully rendered
            const doc = new jsPDF();
            await doc.html(downloadContent, {
                callback: (doc) => {
                    doc.save(`${form.ProjectName}_${report}.pdf`, { returnPromise: true });
                },
                margin: [10, 10, 10, 10],
                autoPaging: 'text',
                filename: `${form.ProjectName}_${report}.pdf`,
                x: 0,
                y: 0,
                width: 180,
                windowWidth: 900,
            });
        } catch (err) {
            console.log(err);
        } finally {
            // Reset the props to stop downloads after rerenders
            if (report === "InvestorTerms") setInvestorTermsProps(undefined);
            else if (report === "LoanTerms") setLoanTermsProps(undefined);
            // setIsDownloading(false)
        }
    }

    const handleDownload = async (report: string) => {
        //TODO: Get company logo, comapny name, phone number, email address from dataSbase
        switch (report) {
            case "Investor":
                setInvestorTermsProps({
                    Logo: <img src={BEPLogo} alt="BEP Logo" width="100px" height="100px" />,
                    CompanyName: new Cookies().get('settings').TenantName || "[Company Name]",
                    PhoneNumber: "[Phone Number]",
                    EmailAddress: "[Email Address]",
                    ProjectName: form.ProjectName,
                    ProjectAddress: form.ProjectAddress,

                    TotalProjectCost: calculations?.TotalCosts || '0',
                    EstimatedSalePrice: form.HouseEstimatedSellPrice,
                    EstimatedGrossProfit: calculations?.GrossProfit || '0',
                    EstimatedGrossMargin: calculations.GrossMargin || '0',
                    ProjectDuration: calculations?.TotalDuration || '0',
                    InterestOnInvestorCash: calculations?.InterestOnInvestmentCashToInvestor || '0',
                    DistributionProjectProfits: '0',
                    TotalCashOut: calculations?.InvestorCashOut || '0',
                    ROE: calculations?.InvestorROE || '0',
                    IRR: calculations?.FinalIRR || '0',

                    InterestOnInvestorCashExpense: calculations?.InterestOnInvestmentCashToInvestor || '0',
                    BuilderProjectManagement: calculations?.ProjectManagementFee || '0',
                    TotalDealExpenses: calculations?.TotalDealExpenses || '0',

                    InvestorCash: form.InvestorCash,
                    BuilderCash: form.BuilderCash,
                    InvestorEquity: calculations?.InvestorEquityPercent || '0',
                    BuilderEquity: calculations?.BuilderEquityPercent || '0',
                    TotalCash: calculations?.TotalCash || '0',

                    FirstProfit: calculations?.DistributionAmountOne || '0',
                    FirstInvestorPercentage: calculations?.InvestorBaseReturnPercent || '0',
                    FirstBuilderPercentage: calculations?.BuilderBaseReturnPercent || '0',
                    NextProfit: calculations?.DistributionAmountTwo || '0',
                    NextInvestorPercentage: calculations?.InvestorGrowthReturnPercent || '0',

                    AnythingAbove: calculations?.DistributionAmountThree || '0',


                    SignatoryName: "[User Name]",
                    SignatoryPosition: "[User Position]",
                })
                break;
            case "Loan":
                setLoanTermsProps({
                    Logo: <img src={BEPLogo} alt="BEP Logo" width="100px" height="100px"/>,
                    CompanyName: new Cookies().get('settings').TenantName || "[Company Name]",
                    PhoneNumber: "[Phone Number]",
                    EmailAddress: "[Email Address]",
                    ProjectName: form.ProjectName,
                    ProjectAddress: form.ProjectAddress,
                    TotalProjectCost: calculations?.TotalCosts || '0',
                    EstimatedSalePrice: form.HouseEstimatedSellPrice,
                    EstimatedGrossProfit: calculations?.GrossProfit || '0',
                    EstimatedGrossMargin: calculations?.GrossMargin || '0',

                    DurationToConstructionStart: form.PreConstructionPeriod,
                    DurationOfConstruction: form.ConstructionPeriod,
                    DurationFromCompletionToSettlement: form.SalePeriod,
                    TotalDuration: calculations?.TotalDuration || '0',
                    LandPurchase: form.LandPurchase,
                    DesignAndPlanning: form.DesignPlanning,
                    SiteImprovements: form.SiteImprovements,
                    DirectConstruction: form.DirectConstruction,
                    ContingencyFundPercent: form.ContingencyPercent,
                    ContingencyFund: calculations.Contingency || '0',
                    TotalConstructionCosts: calculations?.TotalConstructionCosts || '0',

                    SellingFinancingAndDispositionCosts: form.TransferTaxesAndOtherDisposition,
                    TotalCosts: calculations?.TotalCosts || '0',
                    EstimatedLoanAmount: calculations?.LoanAmount || '0',
                    LoanToCostRatio: calculations.LoanToValuePercent || '0',
                    InterestRate: form.CommercialBorrowingInterestRatePercent,
                    LoanTerm: calculations.TotalDuration,
                    EstimatedFinancingAndTransactionCosts: calculations?.TotalFinancingCosts || '0',
                    BorrowingInterest: calculations?.ConstructionInterest || '0',


                    SignatoryName: "[User Name]",
                    SignatoryTitle: "[User Position]",

                })
                break;

        }
    }

    useEffect(() => {
        if (calculations && loanTermsProps) {
            downloadFile("LoanTerms")
        }
    }, [loanTermsProps])

    useEffect(() => {
        if (calculations && investorTermsProps) {
            downloadFile("InvestorTerms")
        }
    }, [investorTermsProps])

    return (
        <>
            {
                isReportSelectOpen && <Modal className="w-min max-w-[900px] p-10 m-20 max-h-[700px] h-min bg-gray-100 overflow-auto rounded-lg" onClose={() => setIsReportSelectOpen(false)}>
                    <div className="flex flex-wrap gap-x-5 gap-y-10 justify-between">
                        <div className="w-max flex flex-nowrap gap-x-4 justify-between text-center text-2xl font-semibold">
                            <svg onMouseDown={() => setIsReportSelectOpen(false)} className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 -960 960 960" width="32px" fill="#000000"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg>

                            <span>Select reports to download</span>
                        </div>
                        {
                            reports.map((report, index) => (
                                <DownloadSquare
                                    key={index}
                                    name={report.name}
                                    handleClick={handleDownload}
                                    svg={report.svg}
                                />

                            ))
                        }
                    </div>
                </Modal>
            }

            <div className="hidden">

                {loanTermsProps && <LoanTerms  {...loanTermsProps} />}

                {investorTermsProps && <InvestorTerms {...investorTermsProps} />}

            </div>


        </>
    )


}


const DownloadSquare = ({ svg, name, handleClick }: { svg: React.ReactNode, name: string, handleClick: (report: string) => void }) => {
    const [isDownloaded, setIsDownloaded] = useState(false);

    const handleDownload = async () => {
        handleClick(name);
        setIsDownloaded(true);
    };

    return (
        <div
            className={`relative flex w-[144px] h-[144px] flex-col items-center justify-center px-4 py-4 rounded-sm gap-x-3 border-2 transition-colors duration-300  cursor-pointer ${isDownloaded
                ? 'border-green-600 bg-gradient-to-br  from-green-300/50 to-green-100/50'
                : 'border-sky-50 hover:border-sky-400 bg-gradient-to-br from-sky-100 to-sky-200'
                }`}
            onMouseDown={handleDownload}
        >
            {/* Main content */}
            <div>{svg}</div>
            <div className="font-semibold text-xl">{name}</div>
        </div>
    );
};

