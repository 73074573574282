
export type JVSpec = {
    // General
    ProjectName: string,
    Description: string,
    ProjectAddress: string,
    // schedule
    PreConstructionPeriod: string,
    ConstructionPeriod: string,
    SalePeriod: string
    // product and Revenue
    HouseEstimatedSellPrice: string,

    // project costs 
    // acquisition
    LandPurchase: string,
    // Planning and Permitting
    DesignPlanning: string,
    // Site Improvements
    SiteImprovements: string,
    // direct construction costs
    DirectConstruction: string,
    // contingency
    ContingencyPercent: string,
    // sales and marketing
    CommissionsPercent: string,
    // Disposition
    TransferTaxesAndOtherDisposition: string,
    // Financing
    CommercialFinanceCosts: string,
    CommercialBorrowingInterestRatePercent: string,
    // Other Project expenses
    OtherProjectExpenses: string,

    // Deal expenses
    ProjectManagementRate: string,
    RateForInvestorCash: string,

    // capital stack 
    InvestorCash: string,
    BuilderCash: string,
    MinimumIRR: string,
    MaximumIRR: string
}

export const JVSpecDefaultValues: JVSpec = {
    // General
    ProjectName: "",
    Description: "",
    ProjectAddress: "",
    // schedule
    PreConstructionPeriod: "",
    ConstructionPeriod: "",
    SalePeriod: "",
    // product and Revenue
    HouseEstimatedSellPrice: "",

    // project costs 
    // acquisition
    LandPurchase: "",
    // Planning and Permitting
    DesignPlanning: "",
    // Site Improvements
    SiteImprovements: "",
    // direct construction costs
    DirectConstruction: "",
    // contingency
    ContingencyPercent: "",
    // sales and marketing
    CommissionsPercent: "",
    // Disposition
    TransferTaxesAndOtherDisposition: "",
    // Financing
    CommercialFinanceCosts: "",
    CommercialBorrowingInterestRatePercent: "",
    // Other Project expenses
    OtherProjectExpenses: "",

    // Deal expenses
    ProjectManagementRate: "",
    RateForInvestorCash: "",

    // capital stack 
    InvestorCash: "",
    BuilderCash: "",
    MinimumIRR: "",
    MaximumIRR: ""
}

export type JVSpecCalculations = {
    TotalDuration: string,
    TotalCash: string,

    Contingency: string,
    Commissions: string,

    CommercialFinanceCostsPercent: string,
    ConstructionInterest: string,
    LoanAmount: string,
    LoanToValuePercent: string,
    TotalFinancingCosts: string,
    TotalConstructionCosts: string,
    TotalCosts: string,

    GrossProfit: string,
    GrossMargin: string
    ProjectManagementFee: string,
    InterestOnInvestmentCashToInvestor: string,
    TotalDealExpenses: string,
    NetIncome: string,

    InvestorEquityPercent: string,
    BuilderEquityPercent: string,

    InvestorBaseReturnPercent: string
    BuilderBaseReturnPercent: string

    DistributionAmountOne: string
    InvestorDistributionAmountOne: string
    BuilderDistributionAmountOne: string
    BalanceAfterDistributionOne: string
    IRRAfterDistributionOne: string

    InvestorGrowthReturnPercent: string
    BuilderGrowthReturnPercent: string

    DistributionAmountTwo: string
    InvestorDistributionAmountTwo: string
    BuilderDistributionAmountTwo: string
    BalanceAfterDistributionTwo: string
    IRRAfterDistributionTwo: string

    InvestorPerformanceReturnPercent: string
    BuilderPerformanceReturnPercent: string

    DistributionAmountThree: string
    InvestorDistributionAmountThree: string
    BuilderDistributionAmountThree: string

    InvestorCashOut: string
    BuilderCashOut: string
    InvestorROE: string
    BuilderROE: string
    FinalIRR: string
}

export type InvestorTermsProps = {
    Logo: JSX.Element;
    CompanyName: string;
    PhoneNumber: string;
    EmailAddress: string;
    ProjectName: string;
    ProjectAddress: string;

    // Project Summary
    TotalProjectCost: string;
    EstimatedSalePrice: string;
    EstimatedGrossProfit: string;
    EstimatedGrossMargin: string;
    ProjectDuration: string;

    // Investor Summary
    InterestOnInvestorCash: string;
    DistributionProjectProfits: string;
    TotalCashOut: string;
    ROE: string;
    IRR: string;

    // Deal Expenses
    InterestOnInvestorCashExpense: string;
    BuilderProjectManagement: string;
    TotalDealExpenses: string;

    // Capital Stack
    InvestorCash: string;
    BuilderCash: string;
    InvestorEquity: string;
    BuilderEquity: string;
    TotalCash: string;

    // Profit Allocation
    FirstProfit: string;
    FirstInvestorPercentage: string;
    FirstBuilderPercentage: string;
    NextProfit: string;
    NextInvestorPercentage: string;
    AnythingAbove: string;

    // Signatory
    SignatoryName: string;
    SignatoryPosition: string;
}

export type LoanTermsProps = {
    Logo: JSX.Element;
    CompanyName: string;
    PhoneNumber: string;
    EmailAddress: string;
    ProjectName: string;
    ProjectAddress: string;

    // Project Summary
    TotalProjectCost: string;
    EstimatedSalePrice: string;
    EstimatedGrossProfit: string;
    EstimatedGrossMargin: string;

    // Project Schedule
    DurationToConstructionStart: string;
    DurationOfConstruction: string;
    DurationFromCompletionToSettlement: string;
    TotalDuration: string;

    // Cost Summary
    LandPurchase: string;
    DesignAndPlanning: string;
    SiteImprovements: string;
    DirectConstruction: string;
    ContingencyFundPercent: string
    ContingencyFund: string;
    TotalConstructionCosts: string;
    SellingFinancingAndDispositionCosts: string;
    TotalCosts: string;

    // Financing Assumptions
    EstimatedLoanAmount: string;
    LoanToCostRatio: string;
    InterestRate: string;
    LoanTerm: string;
    EstimatedFinancingAndTransactionCosts: string;
    BorrowingInterest: string;

    // Signatory
    SignatoryName: string;
    SignatoryTitle: string;

}