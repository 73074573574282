import { Fragment, useEffect, useState } from "react"
import APIRequest from "../../../../helpers/CreateRequest"
import { house } from "../GrossProfitReport/GrossProfitReport"
import { Currency } from "../../../../helpers/InputValueConverter"
import FormatDate from "../../../../helpers/FormatDate"
import MultiSelectField from "../../../../components/atoms/forms/SimpleFields/MultiSelectField"

type community = {
    houses: house[]
    units: number
    totalSales: number
    gross: number
    avgGrossMargin: number
}

type territory = {
    communities: Map<string, community>
    units: number
    totalSales: number
    gross: number
    avgGrossMargin: number
}

type region = {
    territories: Map<string, territory>
    units: number
    totalSales: number
    gross: number
    avgGrossMargin: number
}

type regionMap = Map<string, region>

type totals = {
    units: number
    totalSales: number
    gross: number
    avgGrossMargin: string
}

export type option = {
    name: string
    value: string
}

type houseHistoryFilters = {
    Year: string[]
    SaleStatus: string[]
    SaleEventStatus: string[]
    StartEventStatus: string[]
    SettlementEventStatus: string[]
}

type HouseHistoryOptions = {
    Year: option[]
    SaleStatus: option[]
    SaleEventStatus: option[]
    StartEventStatus: option[]
    SettlementEventStatus: option[]
}

export default function HouseHistory({ ScenarioID, triggerNewData }: { ScenarioID: string,triggerNewData:boolean }) {
    const [housesBackup, setHousesBackup] = useState<house[]>()
    const [regionMap, setRegionMap] = useState<regionMap>()
    const [openRegions, setOpenRegions] = useState<string[]>([])
    const [openTerritories, setOpenTerritories] = useState<string[]>([])
    const [openCommunities, setOpenCommunities] = useState<string[]>([])
    const [totals, setTotals] = useState<totals>({
        units: 0,
        totalSales: 0,
        gross: 0,
        avgGrossMargin: "0.00"
    })

    const [filters, setFilters] = useState<houseHistoryFilters>({
        Year: [],
        SaleStatus: [],
        SaleEventStatus: [],
        StartEventStatus: [],
        SettlementEventStatus: []
    })

    const [options, setOptions] = useState<HouseHistoryOptions>({
        Year: [],
        SaleStatus: [],
        SaleEventStatus: [],
        StartEventStatus: [],
        SettlementEventStatus: []
    })

    const handleData = (housesList: house[]) => {
        setOptions(getFilterOptions(housesList))

        const regionMap: regionMap = new Map()
        const houses = filterData(housesList)

        // create map that will hold all houses filtered down by region, to territory, to community, to house 
        houses.forEach(house => {
            // check if region exists
            const region = regionMap.get(house.RegionNameCode)
            if (region) {
                const territory = region.territories.get(house.TerritoryNameCode)
                if (territory) {
                    const community = territory.communities.get(house.CommunityNameCode)
                    if (community) {
                        // update region, territory, and community totals and add the house to the community

                        community.houses.push(house)
                        community.units++
                        community.totalSales += house.TotalSalesPriceAmount
                        community.gross += house.GrossProfitAmount
                        community.avgGrossMargin = community.gross / community.totalSales

                        territory.units++
                        territory.totalSales += house.TotalSalesPriceAmount
                        territory.gross += house.GrossProfitAmount
                        territory.avgGrossMargin = territory.gross / territory.totalSales

                        region.units++
                        region.totalSales += house.TotalSalesPriceAmount
                        region.gross += house.GrossProfitAmount
                        region.avgGrossMargin = region.gross / region.totalSales
                    } else {
                        // update region and territory totals and create the community and add the house to the map

                        const newCommunity: community = {
                            houses: [house],
                            units: 1,
                            totalSales: house.TotalSalesPriceAmount,
                            gross: house.GrossProfitAmount,
                            avgGrossMargin: house.GrossProfitAmount / house.TotalSalesPriceAmount
                        }

                        territory.units++
                        territory.totalSales += house.TotalSalesPriceAmount
                        territory.gross += house.GrossProfitAmount
                        territory.avgGrossMargin = territory.gross / territory.totalSales

                        region.units++
                        region.totalSales += house.TotalSalesPriceAmount
                        region.gross += house.GrossProfitAmount
                        region.avgGrossMargin = region.gross / region.totalSales

                        territory.communities.set(house.CommunityNameCode, newCommunity)
                    }
                } else {
                    // update region totals, create the territory and add the community and the house to the map
                    const newCommunity: community = {
                        houses: [house],
                        units: 1,
                        totalSales: house.TotalSalesPriceAmount,
                        gross: house.GrossProfitAmount,
                        avgGrossMargin: house.GrossProfitAmount / house.TotalSalesPriceAmount
                    }
                    const territory: territory = {
                        communities: new Map().set(house.CommunityNameCode, newCommunity),
                        units: 1,
                        totalSales: house.TotalSalesPriceAmount,
                        gross: house.GrossProfitAmount,
                        avgGrossMargin: house.GrossProfitAmount / house.TotalSalesPriceAmount
                    }

                    region.territories.set(house.TerritoryNameCode, territory)
                    region.units++
                    region.totalSales += house.TotalSalesPriceAmount
                    region.gross += house.GrossProfitAmount
                    region.avgGrossMargin = region.gross / region.totalSales

                    region.territories.set(house.TerritoryNameCode, territory)
                }
            } else {
                // TODO: create the region and add the territory and community and the house to the map
                const newCommunity: community = {
                    houses: [house],
                    units: 1,
                    totalSales: house.TotalSalesPriceAmount,
                    gross: house.GrossProfitAmount,
                    avgGrossMargin: house.GrossProfitAmount / house.TotalSalesPriceAmount
                }
                const newTerritory: territory = {
                    communities: new Map().set(house.CommunityNameCode, newCommunity),
                    units: 1,
                    totalSales: house.TotalSalesPriceAmount,
                    gross: house.GrossProfitAmount,
                    avgGrossMargin: house.GrossProfitAmount / house.TotalSalesPriceAmount
                }

                const newRegion: region = {
                    territories: new Map().set(house.TerritoryNameCode, newTerritory),
                    units: 1,
                    totalSales: house.TotalSalesPriceAmount,
                    gross: house.GrossProfitAmount,
                    avgGrossMargin: house.GrossProfitAmount / house.TotalSalesPriceAmount
                }

                regionMap.set(house.RegionNameCode, newRegion)
            }
        })

        setRegionMap(regionMap)

        const units = houses.reduce((acc, house) => acc + 1, 0)
        const totalSales = houses.reduce((acc, house) => acc + house.TotalSalesPriceAmount, 0)
        const gross = houses.reduce((acc, house) => acc + house.GrossProfitAmount, 0)
        const avgGrossMargin = ((gross / totalSales * 100) || 0).toFixed(2)

        setTotals({
            units: units,
            totalSales: totalSales,
            gross: gross,
            avgGrossMargin: avgGrossMargin
        })
    }

    // filter the data based on the selected filters
    const filterData = (houses: house[]) => {
        let filteredHouses = houses

        // remove houses with the same house code in the same community name
        filteredHouses = filteredHouses.filter((house, index, self) => {
            return index === self.findIndex(h => h.HouseCode === house.HouseCode && h.CommunityNameCode === house.CommunityNameCode)
        })

        // filter by filtersState 
        filteredHouses = filteredHouses.filter(house => {
            if (filters.Year.length > 0 && !filters.Year.includes(new Date(house.SaleEventDate).getFullYear().toString()) && !filters.Year.includes(new Date(house.StartEventDate).getFullYear().toString()) && !filters.Year.includes(new Date(house.SettlementEventDate).getFullYear().toString())) return false
            if (filters.SaleStatus.length > 0 && !filters.SaleStatus.includes(house.SaleStatus)) return false
            if (filters.SaleEventStatus.length > 0 && !filters.SaleEventStatus.includes(house.SaleEventStatus)) return false
            if (filters.StartEventStatus.length > 0 && !filters.StartEventStatus.includes(house.StartEventStatus)) return false
            if (filters.SettlementEventStatus.length > 0 && !filters.SettlementEventStatus.includes(house.SettlementEventStatus)) return false
            return true
        })

        return filteredHouses
    }

    const getFilterOptions = (houses: house[]) => {
        // get the unique values for each filter
        const filters: HouseHistoryOptions = {
            Year: [],
            SaleStatus: [],
            SaleEventStatus: [],
            StartEventStatus: [],
            SettlementEventStatus: []
        }

        houses.forEach(house => {
            const saleYear = new Date(house.SaleEventDate).getFullYear().toString()
            const startYear = new Date(house.StartEventDate).getFullYear().toString()
            const settlementYear = new Date(house.SettlementEventDate).getFullYear().toString()
            if (!filters.Year.some(option => option.value === saleYear)) filters.Year.push({ value: saleYear, name: saleYear })
            if (!filters.Year.some(option => option.value === startYear)) filters.Year.push({ value: startYear, name: startYear })
            if (!filters.Year.some(option => option.value === settlementYear)) filters.Year.push({ value: settlementYear, name: settlementYear })
            if (!filters.SaleStatus.some(option => option.value === house.SaleStatus)) filters.SaleStatus.push({ value: house.SaleStatus, name: house.SaleStatus })
            if (!filters.SaleEventStatus.some(option => option.value === house.SaleEventStatus)) filters.SaleEventStatus.push({ value: house.SaleEventStatus, name: house.SaleEventStatus })
            if (!filters.StartEventStatus.some(option => option.value === house.StartEventStatus)) filters.StartEventStatus.push({ value: house.StartEventStatus, name: house.StartEventStatus })
            if (!filters.SettlementEventStatus.some(option => option.value === house.SettlementEventStatus)) filters.SettlementEventStatus.push({ value: house.SettlementEventStatus, name: house.SettlementEventStatus })
        })

        // order the options
        filters.Year.sort((a, b) => a.value.localeCompare(b.value))
        filters.SaleStatus.sort((a, b) => a.name.localeCompare(b.name))
        filters.SaleEventStatus.sort((a, b) => a.name.localeCompare(b.name))
        filters.StartEventStatus.sort((a, b) => a.name.localeCompare(b.name))
        filters.SettlementEventStatus.sort((a, b) => a.name.localeCompare(b.name))

        return filters
    }

    const handleClickedObject = (type: 'region' | 'territory' | 'community', name: string) => {
        if (type === 'region') {
            if (openRegions.includes(name)) {
                setOpenRegions(openRegions.filter(region => region !== name))
            } else {
                setOpenRegions([...openRegions, name])
            }
        } else if (type === 'territory') {
            if (openTerritories.includes(name)) {
                setOpenTerritories(openTerritories.filter(territory => territory !== name))
            } else {
                setOpenTerritories([...openTerritories, name])
            }
        } else if (type === 'community') {
            if (openCommunities.includes(name)) {
                setOpenCommunities(openCommunities.filter(community => community !== name))
            } else {
                setOpenCommunities([...openCommunities, name])
            }
        }
    }

    const getData = async () => {
        try {
            const result = await new APIRequest(`/scenariosSalesForecastEvents/grossProfit`, 'POST', null, { scenarioID: ScenarioID }).GenerateRequest()
            if (result.status === 200) {
                const body = await result.json()
                if (body.status === 200) {
                    setHousesBackup(body.recordset)
                    handleData(body.recordset)
                } else throw body.message
            } else throw result.statusText
        } catch (err) {
            console.log(err)
        }
    }

    //call the API to get the data for the selected scenario
    useEffect(() => {
        if (ScenarioID) getData()
    }, [ScenarioID, triggerNewData])

    // filter data when filters change
    useEffect(() => {
        if (housesBackup) handleData(housesBackup)
    }, [filters])

    return (
        <div className="w-full h-full">

            <div className="flex gap-x-6 gap-y-2 items-center h-max min-w-full flex-wrap py-3 bep-shadow-xl rounded-lg px-4">
                <span className="headline-large">House History</span>
                <MultiSelectField name="Year" label="Year" setValue={setFilters} size="sm" options={options.Year} selected={filters?.Year || []} />
                <MultiSelectField name="SaleStatus" label="Sale Status" setValue={setFilters} size="sm" options={options.SaleStatus} selected={filters?.SaleStatus || []} />
                <MultiSelectField name="SaleEventStatus" label="Sale Event Status" setValue={setFilters} size="sm" options={options.SaleEventStatus} selected={filters?.SaleEventStatus || []} />
                <MultiSelectField name="StartEventStatus" label="Start Event Status" setValue={setFilters} size="sm" options={options.StartEventStatus} selected={filters?.StartEventStatus || []} />
                <MultiSelectField name="SettlementEventStatus" label="Settlement Event Status" setValue={setFilters} size="sm" options={options.SettlementEventStatus} selected={filters?.SettlementEventStatus || []} />
            </div>

            <div className="bep-shadow-xl rounded-lg px-4 py-3 w-full mt-5">
                <table className="w-full border-2 border-x-2 text-sm text-nowrap">
                    <thead className="sticky -top-3 border-b-2 bg-white">
                        <tr>
                            <th className="p-2 border-b-2">Region</th>
                            <th className="p-2 border-b-2">Units</th>
                            <th className="p-2 border-b-2">Sale Status</th>
                            <th className="p-2 border-b-2">Total Sales</th>
                            <th className="p-2 border-b-2">Gross</th>
                            <th className="p-2 border-b-2">Avg. Gross Margin %</th>
                            <th className="p-2 border-b-2">Sale Date</th>
                            <th className="p-2 border-b-2">Status</th>
                            <th className="p-2 border-b-2">Start Date</th>
                            <th className="p-2 border-b-2">Status</th>
                            <th className="p-2 border-b-2">Settlement Date</th>
                            <th className="p-2 border-b-2">Status</th>
                        </tr>
                    </thead>

                    <tbody className="text-right">
                        {
                            regionMap ?

                                [...regionMap.keys()].map((region, regIndex) => {
                                    const regionTotals = regionMap.get(region)
                                    return <Fragment key={'region' + region + regIndex}>
                                        <tr className="even:bg-gray-200">
                                            <td className="table-cell cursor-pointer text-left font-bold" onClick={() => handleClickedObject('region', region)}>{openRegions.includes(region) ? '-' : '+'} {region}</td>
                                            <TableCell text={regionTotals?.units.toString()} />
                                            <TableCell />
                                            <TableCell text={Currency(regionTotals?.totalSales || 0)} />
                                            <TableCell text={Currency(regionTotals?.gross || 0)} />
                                            <TableCell text={((regionTotals?.avgGrossMargin || 0) * 100).toFixed(2) + "%"} />
                                            <TableCell />
                                            <TableCell />
                                            <TableCell />
                                            <TableCell />
                                            <TableCell />
                                            <TableCell />
                                        </tr>

                                        {
                                            openRegions.includes(region) && [...(regionMap.get(region)?.territories || new Map()).keys()].map((territory, terIndex) => {
                                                const territoryTotals = regionMap.get(region)?.territories.get(territory)

                                                return <Fragment key={'territory' + territory + region}>
                                                    <tr className="even:bg-gray-200">
                                                        <td className="hover:bg-blue-100 border p-1 pl-4 cursor-pointer text-left font-bold" onClick={() => handleClickedObject('territory', territory)}>{openTerritories.includes(territory) ? '-' : '+'} {territory}</td>
                                                        <TableCell text={territoryTotals?.units.toString()} />
                                                        <TableCell />
                                                        <TableCell text={Currency(territoryTotals?.totalSales || 0)} />
                                                        <TableCell text={Currency(territoryTotals?.gross || 0)} />
                                                        <TableCell text={((territoryTotals?.avgGrossMargin || 0) * 100).toFixed(2) + "%"} />
                                                        <TableCell />
                                                        <TableCell />
                                                        <TableCell />
                                                        <TableCell />
                                                        <TableCell />
                                                        <TableCell />
                                                    </tr>

                                                    {
                                                        openTerritories.includes(territory) && [...(regionMap.get(region)?.territories?.get(territory)?.communities || new Map()).keys()].map(community => {
                                                            const communityTotals = regionMap.get(region)?.territories?.get(territory)?.communities.get(community)

                                                            return <Fragment key={'community' + community + territory + region}>
                                                                <tr className="even:bg-gray-200">
                                                                    <td className="hover:bg-blue-100 border pl-7 cursor-pointer text-left font-bold" onClick={() => handleClickedObject('community', community)}>{openCommunities.includes(community) ? '-' : '+'} {community}</td>
                                                                    <TableCell text={communityTotals?.units.toString()} />
                                                                    <TableCell />
                                                                    <TableCell text={Currency(communityTotals?.totalSales || 0)} />
                                                                    <TableCell text={Currency(communityTotals?.gross || 0)} />
                                                                    <TableCell text={((communityTotals?.avgGrossMargin || 0) * 100).toFixed(2) + "%"} />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                    <TableCell />
                                                                </tr>


                                                                {
                                                                    openCommunities.includes(community) && (regionMap.get(region)?.territories?.get(territory)?.communities.get(community)?.houses || []).map(house => (
                                                                        <tr key={house.CommunityNameCode + house.HouseCode} className="even:bg-gray-200">
                                                                            <td className="hover:bg-blue-100 border pl-10 text-left" >{house.HouseCode}</td>
                                                                            <TableCell />
                                                                            <TableCell text={house.SaleStatus} />
                                                                            <TableCell text={Currency(house.TotalSalesPriceAmount)} />
                                                                            <TableCell text={Currency(house.GrossProfitAmount)} />
                                                                            <TableCell text={(house.GrossProfitAmount / house.TotalSalesPriceAmount * 100).toFixed(2) + "%"} />
                                                                            <TableCell text={FormatDate(house.SaleEventDate)} />
                                                                            <TableCell text={house.SaleStatus} />
                                                                            <TableCell text={FormatDate(house.StartEventDate)} />
                                                                            <TableCell text={house.StartEventStatus} />
                                                                            <TableCell text={FormatDate(house.SettlementEventDate)} />
                                                                            <TableCell text={house.SettlementEventStatus} />
                                                                        </tr>
                                                                    ))
                                                                }

                                                            </Fragment>
                                                        })
                                                    }
                                                </Fragment>
                                            })
                                        }
                                    </Fragment>
                                })

                                :

                                <tr className="text-center font-bold h-20">
                                    <td colSpan={12}>Please select a scenario</td>
                                </tr>
                        }
                        <tr className="font-bold">
                            <TableCell text="Totals" className="text-left" />
                            <TableCell text={totals.units.toString()} />
                            <TableCell />
                            <TableCell text={Currency(totals.totalSales)} />
                            <TableCell text={Currency(totals.gross)} />
                            <TableCell text={totals.avgGrossMargin + "%"} />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}


function TableCell({ text, className }: { text?: string, className?: string }) {
    return (
        <td className={`table-cell ${className}`}>{text}</td>
    )
}