
type InputProps = {
    className?: string;
    placeholder?: string;
    type?: string;
    name?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    autoFocus?: boolean;
    style?: React.CSSProperties;
}

export default function Input(props: InputProps){


    return(
        <input
        className={'w-[340px] h-12 mt-1 border-2 border-gray-700 p-2 pl-4 text-gray-700 bg-white rounded-md focus:border-2 focus:border-blue-300 focus:shadow-none outline-none ' + props.className}
        name={props.name}
        type={props.type}
        placeholder={props.placeholder}
        onChange={props.onChange}
        style={props.style}
        autoFocus={props.autoFocus}
      />
    )
}