import { useEffect, useState } from "react"
import { UseFormSetValue } from "react-hook-form"
import APIRequest from "../../../../helpers/CreateRequest"

export type SelectFieldType = {
    name: string,
    label: string,
    size?: 'sm' | 'md' | 'lg' | 'xl',
    supportingText?: string,
    disabled?: boolean,
    required?: boolean,
    value?: string,
    setValue: UseFormSetValue<any>
    fieldNames: {
        name: string;
        namePlural: string;
    }
    staticOptions?: {
        name: string,
        value: any,
    }[]
    setWrapperState?: Function,
    requestDetails?: {
        path: string,
        method: "GET" | "POST" | "PUT" | "DELETE"
        header?: any
        body?: any
        resultName: string
        resultIDName: string
    }
}

// Known issues
// Does not swap to dropup if no space to fit underneath 
// when focused, if you click away to anaother window, th focus leaves and the dropdown closes, but the first time you reclick
// on the select again it will focus and then blur 

export default function SelectField({
    name, label, size, supportingText, disabled, required, value, setValue, fieldNames, staticOptions, setWrapperState, requestDetails
}: SelectFieldType) {
    const [isFocused, setIsFocused] = useState(false)
    const [tempValue, setTempValue] = useState<string>('')
    const [options, setOptions] = useState<{ name: string, value: any, index: number }[]>(staticOptions?.map((option, index) => { return { ...option, index: index } }) || [])
    const [selectedOption, setSelectedOption] = useState(0)
    const [maxOption, setMaxOption] = useState(0)

    const getNames = async () => {
        const tempOptions: { name: string, value: any, index: number }[] = []
        try {
            const result = await (new APIRequest(requestDetails?.path || `/${fieldNames.namePlural.replace(/\s+/g, '')}/names`, requestDetails?.method || 'GET', requestDetails?.header || null, requestDetails?.body || null).GenerateRequest())
            if (result.status === 200) {
                const body = await result.json()
                const objs: any[] = body.recordset || []
                let i = 0
                if (objs.length > 0) {
                    for (i; i < objs.length; i++) {
                        const tempName = requestDetails ? objs[i][requestDetails.resultName] : objs[i].Name || objs[i][`${fieldNames.name.replace(/\s+/g, '')}Name`] || objs[i][`${fieldNames.name.replace(/\s+/g, '')}NameCode`]
                        const tempID = requestDetails ? objs[i][requestDetails.resultIDName] : objs[i][`${fieldNames.name.replace(/\s+/g, '')}ID`]
                        tempOptions.push({ name: tempName, value: tempID, index: i })
                    }
                    setMaxOption(i - 1)
                }
            } else {
                tempOptions.push({ name: `Error...`, value: undefined, index: 0 })

            }
            setOptions(tempOptions)
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        setIsFocused(false)
    }

    //handles when the user selects an option. If option exists this was a click, else it was using arrow keys and enter
    const handleSelect = (option?: { name: string, value?: string, index: number }) => {
        if (option) {
            setTempValue(option.name)
            setValue(name, option.value, { shouldDirty: true })
            setSelectedOption(option.index)
            if (setWrapperState) setWrapperState(option.value)
        } else {
            options.forEach(option => {
                if (option.index === selectedOption) {
                    setTempValue(option.name)
                    setValue(name, option.value, { shouldDirty: true })
                    setIsFocused(false)
                    if (setWrapperState) setWrapperState(option.value)
                }
            })
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        //allowed exeptions
        const EXCEPTION = e.ctrlKey || e.altKey || e.metaKey || e.key === 'Tab'
        //if arrow up or down, move the focus up or down, if enter then handleSelect, ele prevent the default
        if (e.key === 'ArrowDown') setSelectedOption(selectedOption >= maxOption ? 0 : selectedOption + 1)
        else if (e.key === 'ArrowUp') setSelectedOption(selectedOption <= 0 ? maxOption : selectedOption - 1)
        else if (e.key === 'Enter') handleSelect()
        else if (!EXCEPTION) e.preventDefault()

        //If a letter was typed, it moves to the first options starting with that value
        for (let i = 0; i < options.length; i++) {
            if (options[i].name.toLowerCase().startsWith(e.key.toLowerCase())) {
                setSelectedOption(options[i].index)
                break;
            }
        }
    }

    const handleMouseDown = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        // if focused, blur the input, set the focus state to false, and prevent the default mouse down functionality (focus)
        if (isFocused) {
            e.currentTarget.blur()
            setIsFocused(false)
            e.preventDefault()
        } else {
            setIsFocused(true)
        }
    }

    // Gets the list of names for the object
    useEffect(() => {
        if (!staticOptions) getNames()
    }, [value])

    //if option matches set as temp value
    useEffect(() => {
        let found = false
        options.forEach(option => {
            if (option.value === value) {
                setTempValue(option.name)
                setValue(name, option.value, { shouldDirty: true })
                found = true
            }
        })
        if (!found) setTempValue('')

    }, [value, options])

    //get element by id that matches the selectedOption 
    useEffect(() => {
        const element = document.getElementById(`selectFieldOptions${name}${label}${selectedOption}`)
        if (element) element.scrollIntoView({ block: 'nearest' })
    }, [selectedOption])


    return (
        <div key={`${label}${name}`} className=" font-sans overflow-visible">
            <div className="relative">
                <input
                    type="text"
                    disabled={disabled}
                    required={required}
                    className={`h-[40px] ${size === 'sm' ? 'w-[125px]' : size === 'lg' ? 'w-[400px]' : 'w-[200px]'} rounded-[2px] outline-none outline-[1.5px] text-md-on-surface body-large  pr-[32px] pl-[14px] py-[16px] transition-opacity duration-300 caret-transparent
                    ${disabled ? '  outline-gray-300 dark:outline-gray-300 text-opacity-[.38] cursor-default' : ' outline-md-outline cursor-pointer '}
                    ${isFocused ? ' outline-[2.5px]  text-black outline-md-primary text-md-on-surface' : ''}
                     ${!disabled && !isFocused ? 'hover:outline-md-on-surface hover:text-md-on-surface' : ''}
                    `}
                    onMouseDown={handleMouseDown}
                    onKeyDown={handleKeyDown}
                    onFocus={() => setIsFocused(true)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={tempValue}
                />

                <svg xmlns="http://www.w3.org/2000/svg" fill={` ${!disabled && !isFocused ? 'var(--md-sys-color-on-surface-variant)' : ''}  ${disabled ? 'var(--md-sys-color-on-surface)' : ''} ${isFocused ? 'var(--md-sys-color-primary)' : ''} `} className={` absolute top-[8px] ${size === 'sm' ? 'left-[120px]' : size === 'lg' ? 'left-[370px]' : 'left-[172px] '} pointer-events-none transition-all duration-300 ${isFocused ? 'rotate-180' : ''} ${disabled ? 'opacity-[.38]' : ''}`} height="24" viewBox="0 -960 960 960" width="24"><path d="M480-360 280-560h400L480-360Z" /></svg>

                <label
                    htmlFor={name}
                    className={`absolute flex items-center transition-all duration-300 h-[16px] pointer-events-none z-10 body-large text-nowrap
                    ${isFocused || (disabled && value) || tempValue ? ` ${isFocused ? 'text-md-primary' : ''} top-[-12px] bg-white px-1 left-[4px] body-large ` : 'top-[12px] left-[16px] text-md-on-surface-variant bg-transparent'}
                    ${required ? "after:content-['*'] after:ml.5" : ''}
                    ${disabled ? ` ${!value ? 'opacity-[.38]' : ''} text-md-on-surface body-large` : ''}
                     `}
                >
                    {label}
                </label>

                {/* Supporting text */}
                <div className={`font-sans text-[12px] tracking-[0.3px] pt-[4px] px-[16px] 
        ${disabled ? ' text-md-on-surface opacity-[.38]' : 'text-md-on-surface-variant hover:text-md-on-surface-variant'}
        ${isFocused ? ' text-md-on-surface-variant' : ''}
        `}>{supportingText}</div>

                <ul className={`absolute w-[208px] left-[-4px] z-50 overflow-auto  text-md-on-secondary-container bg-[--md-ref-palette-primary98] shadow-black rounded-[2px] max-h-52 py-[4px] shadow-sm 
                     ${disabled ? ' text-md-on-surface opacity-[.38]' : ''}
                     ${!disabled && !isFocused ? 'text-opacity-100 hover:text-md-on-surface body-large' : ''}
                     ${isFocused ? '' : 'invisible'}
                     `}
                >
                    {
                        options ?

                            options.length > 0 ?

                                options.map((option, index) => {
                                    return (
                                        <li
                                            id={`selectFieldOptions${name}${label}${index}`}
                                            key={`select${option.name}${option.value}`}
                                            onMouseDown={() => handleSelect(option)}
                                            className={`cursor-pointer w-full px-[6px] py-[8px] hover:bg-gray-200 break-words 
                                             text-md-on-surface label-large
                                             hover:text-md-on-surface
                                             hover:bg-[--md-ref-palette-primary80]
                                             focus:bg-md-secondary focus:text-md-on-surface
                                             ${selectedOption === index ? 'bg-gray-300' : ''}
                                            `}
                                        >
                                            {option.name}
                                        </li>
                                    )
                                })

                                :

                                <li key={'dropdownOptionNoneMatch'} className="w-full px-4 py-2 text-sm ">
                                    No results found
                                </li>

                            :

                            <li key={'dropdownOptionNoneMatch'} className="w-full px-4 py-2 text-sm ">
                                Loading...
                            </li>
                    }
                </ul>
            </div>
        </div>
    )
}