import { InvestorTermsProps } from "./JVSpecTypes";

export default function InvestorTerms(props: InvestorTermsProps): JSX.Element {
    return (
        <div id="InvestorTerms" className="text-pretty">
            <header className="grid grid-cols-12 border-b-2 py-2">
                <span className="col-span-3">{props.Logo}</span>
                <span className="col-span-9 justify-self-end">{new Date().toLocaleDateString()}</span>

                <span className="col-span-12">{props.CompanyName}</span>
                <span className="col-span-12">{props.EmailAddress} | {props.PhoneNumber}</span>

                <span className="col-span-12 text-center font-bold">Project Lending Opportunity</span>

                <span className="col-span-12">{props.ProjectName}</span>
                <span className="col-span-12">{props.ProjectAddress}</span>
            </header>

            <main className="grid grid-cols-12 gap-y-2">
                {/* Purpose */}
                <span className="font-bold col-span-2">Purpose</span>
                <div className="grid grid-cols-12 col-span-10">
                    <span className="col-span-10">&nbsp;</span>
                    <span className="col-span-10">
                        To secure financing for a high-quality, luxury single-family spec home that meets market demand. The project aims to achieve a solid return on investment through careful planning, efficient construction, and thoughtful market positioning.
                    </span>
                </div>

                {/* Project Summary */}
                <span className="font-bold col-span-12">Project Summary</span>
                <div className="col-span-2" />
                <div className="col-span-10">
                    <div className="grid grid-cols-12">
                        <span className="col-span-4">Total Estimated Project Duration</span>
                        <span className="col-span-8">{props.ProjectDuration} months</span>

                        <span className="col-span-4">Total Project Cost</span>
                        <span className="col-span-8">{props.TotalProjectCost}</span>

                        <span className="col-span-4">Estimated Sale Price</span>
                        <span className="col-span-8">{props.EstimatedSalePrice}</span>

                        <span className="col-span-4">Estimated Gross Profit</span>
                        <span className="col-span-8">{props.EstimatedGrossProfit}</span>

                        <span className="col-span-4">Estimated Gross Margin</span>
                        <span className="col-span-8">{props.EstimatedGrossMargin}%</span>
                    </div>
                </div>

                {/* Investor Summary */}
                <span className="font-bold col-span-12">Investor Summary</span>
                <div className="col-span-2" />
                <div className="col-span-10">
                    <div className="grid grid-cols-12">
                        <span className="col-span-4">Interest on Investor Cash (10%)</span>
                        <span className="col-span-8">{props.InterestOnInvestorCash}</span>

                        <span className="col-span-4">Distribution Project Profits</span>
                        <span className="col-span-8">{props.DistributionProjectProfits}</span>

                        <span className="col-span-4">Total Cash Out</span>
                        <span className="col-span-8">{props.TotalCashOut}</span>

                        <span className="col-span-4">ROE</span>
                        <span className="col-span-8">{props.ROE}%</span>

                        <span className="col-span-4">IRR</span>
                        <span className="col-span-8">{props.IRR}%</span>
                    </div>
                </div>

                {/* Deal Expenses */}
                <span className="font-bold col-span-2">Deal Expenses</span>
                <div className="col-span-10 grid grid-cols-12">
                    <span className="col-span-6">&nbsp;</span>
                    <span className="col-span-3 font-bold">Investor</span>
                    <span className="col-span-3 font-bold">Builder</span>
                </div>

                <div className="col-span-2" />
                <div className="col-span-10">
                    <div className="grid grid-cols-12">
                        <span className="col-span-6">Interest on Investor Cash (10%)</span>
                        <span className="col-span-6">{props.InterestOnInvestorCashExpense}</span>

                        <span className="col-span-9">Builder Project Management (15%)</span>
                        <span className="col-span-3">{props.BuilderProjectManagement}</span>

                        <span className="col-span-4">Total Deal Expenses</span>
                        <span className="col-span-6">{props.TotalDealExpenses}</span>
                    </div>
                </div>

                {/* Capital Stack */}
                <span className="font-bold col-span-12">Capital Stack</span>
                <div className="col-span-2" />
                <div className="col-span-10">
                    <div className="grid grid-cols-12">
                        <span className="col-span-6">Cash</span>
                        <span className="col-span-3">{props.InvestorCash}</span>
                        <span className="col-span-3">{props.BuilderCash}</span>

                        <span className="col-span-6">Equity</span>
                        <span className="col-span-3">{props.InvestorEquity}%</span>
                        <span className="col-span-3">{props.BuilderEquity}%</span>

                        <span className="col-span-4">Total Cash</span>
                        <span className="col-span-8">{props.TotalCash}</span>
                    </div>
                </div>

                {/* Profit Allocation */}
                <span className="font-bold col-span-12">Profit Allocation</span>
                <div className="col-span-2" />
                <div className="col-span-10">
                    <div className="grid grid-cols-12">
                        <span className="col-span-4">First</span>
                        <span className="col-span-2">{props.FirstProfit}</span>
                        <span className="col-span-3">{props.FirstInvestorPercentage}%</span>
                        <span className="col-span-3">{props.FirstBuilderPercentage}%</span>

                        <span className="col-span-4">Next</span>
                        <span className="col-span-2">{props.NextProfit}</span>
                        <span className="col-span-6">{props.NextInvestorPercentage}%</span>

                        <span className="col-span-4">Anything Above</span>
                    </div>
                </div>

                {/* Signatory */}
                <span className="col-span-12 mt-4">{props.SignatoryName}</span>
                <span className="col-span-12">{props.SignatoryPosition}</span>
                <span className="col-span-12">{props.CompanyName}</span>
                <span className="col-span-12">{props.PhoneNumber}</span>
                <span className="col-span-12">{props.EmailAddress}</span>
            </main>
        </div>
    );
}
