import React from "react";


export default function CheckMarkProgress(props: { progress: number, steps: string[] }) {
    const width = `${props.steps.length * 122 + 5 - 30}px`; //steps * (circle width + line width) + padding - line width

    return (
        <span className="flex flex-wrap">
            <svg width={width} height="100px" viewBox={`0 0 ${props.steps.length * 122 + 5 - 80} 100`} xmlns="http://www.w3.org/2000/svg">
                {

                    // Generate lines first
                    props.steps.map((step, index) => (
                        props.steps.length > index + 1
                        &&
                        <line
                            x1={40 + index * 122 + 5}
                            y1="30"
                            x2={42 + index * 122 + 80 + 5}
                            y2="30"
                            stroke={index + 1 > props.progress ? '#d3d3d3' : '#66bb6a'}
                            strokeWidth="2"
                            key={`line-${index}`}
                        />
                    ))
                }
                {
                    // Generate circles and text
                    props.steps.map((step, index) => (
                        <React.Fragment key={`circle-text-${index}`}>
                            <circle
                                cx={index * 122 + 20 + 5}
                                cy="30"
                                r="20"
                                fill={index > props.progress ? '#d3d3d3' : '#66bb6a'}
                                stroke={index > props.progress ? '#d3d3d3' : '#66bb6a'}
                                strokeWidth="2" fillOpacity={props.progress > index ? 1 : 0}
                                key={`circle-${index}`}

                            />
                            {props.progress > index && <path
                                d={`M${12 + index * 122 + 5},30 L${18 + index * 122 + 5},36 L${30 + index * 122 + 5},24`}
                                stroke="white"
                                strokeWidth="2"
                                fill="none"
                                key={`check-${index}`}
                            />}
                            <text
                                x={index * 122 + 20 + 5}
                                y="70"
                                textAnchor="middle"
                                fontSize="15"
                                fill="#000"
                                key={`text-${index}`}
                            >

                                {step}
                            </text>
                        </React.Fragment>
                    ))
                }
            </svg>
        </span>
    )
}